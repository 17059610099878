import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Collapse,
  IconButton
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MdOutlineExpandMore, MdAccessTime } from "react-icons/md";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import SpeakerModal from "./SpeakerModal.js";
import programSlotsApi from "../../../api/ProgramSlotsApi.js";
import eventDaysApi from "../../../api/EventDaysApi.js";
import { notification } from "antd";
import PostedItineraryView from "./PostedItineraryView";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#2D9687",
    },
  },
});

const checkDateTimeOverlap = (startDate, endDate, newDate) => {
  const start = Math.floor(startDate * 1000);
  const end = Math.floor(endDate * 1000);
  const selected = Math.floor(newDate * 1000);

  return selected >= start && selected <= end;
};

const EventItineraryTab = ({ eventData, speakersData, eventDays: initialEventDays }) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const [expandedProgram, setExpandedProgram] = useState(null);
  const [startDate] = useState(eventData?.startDate ? new Date(eventData?.startDate * 1000) : null);
  const [endDate] = useState(eventData?.endDate ? new Date(eventData?.endDate * 1000) : null);
  const daysDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  const [initialDay, setInitialDay] = useState(1);
  const [timeRangeError, setTimeRangeError] = useState("");
  const [timeErrors, setTimeErrors] = useState({});
  const [itineraryPosted, setItineraryPosted] = useState(false);
  const [postedEventDays, setPostedEventDays] = useState(initialEventDays);

  const [days, setDays] = useState(Array.from({ length: daysDifference }, (_, index) => ({
    eventId: eventData?.id,
    title: `Day ${index + 1}`,
    startTime: null,
    endDate: null,
    programSlots: [
      {
        eventId: eventData?.id,
        eventDayId: initialDay,
        speakerId: null,
        title: `Event Program 1`,
        description: "",
        startTime: null,
        endTime: null,
      },
      {
        eventId: eventData?.id,
        eventDayId: 2,
        speakerId: null,
        title: `Event Program 2`,
        description: "",
        startTime: null,
        endTime: null,
      }
    ],
  })));
  
  const [openModal, setOpenModal] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [currentProgramIndex, setCurrentProgramIndex] = useState(null);
  
  const handleDayChange = (index) => {
    setSelectedDay(index);
    setExpandedProgram(null);
  };
  
  const handleExpandClick = (programIndex) => {
    setExpandedProgram(expandedProgram === programIndex ? null : programIndex);
  };
  
  const handleInputChange = (field, value) => {
    setDays(prevDays => {
      const newDays = [...prevDays];
      newDays[selectedDay] = { ...newDays[selectedDay], [field]: value };
      return newDays;
    });
  };
  
  const handleProgramInputChange = (programIndex, field, value) => {
    if (field === 'startTime' || field === 'endTime') {
      const currentDay = days[selectedDay];
      const currentProgram = currentDay.programSlots[programIndex];
      
      let isValid = true;
      let errorMessage = '';

      const eventDayStart = dayjs.unix(eventData.startDate).add(selectedDay, 'day').startOf('day');
      const eventDayEnd = eventDayStart.endOf('day');
      
      const selectedDateTime = eventDayStart.hour(value.hour()).minute(value.minute()).second(0);
      const selectedUnixTime = selectedDateTime.unix();

      if (programIndex === 0) {
        const eventStart = dayjs.unix(eventData.startDate);
        const eventEnd = dayjs.unix(eventData.endDate);
        isValid = checkDateTimeOverlap(eventData.startDate, eventData.endDate, selectedUnixTime);
        errorMessage = `Selected time must be between ${eventStart.format('HH:mm')} and ${eventEnd.format('HH:mm')}.`;
      } else {
        const previousProgram = currentDay.programSlots[programIndex - 1];

        if (field === 'startTime') {
          const minTime = previousProgram.endTime || eventDayStart;
          isValid = checkDateTimeOverlap(minTime.unix(), eventDayEnd.unix(), selectedUnixTime);
          errorMessage = `Start time must be between ${minTime.format('HH:mm')} and ${eventDayEnd.format('HH:mm')}.`;
        } else {
          const minTime = currentProgram.startTime || eventDayStart;
          isValid = checkDateTimeOverlap(minTime.unix(), eventDayEnd.unix(), selectedUnixTime);
          errorMessage = `End time must be between ${minTime.format('HH:mm')} and ${eventDayEnd.format('HH:mm')}.`;
        }
      }

      if (!isValid) {
        setTimeErrors(prev => ({
          ...prev,
          [programIndex]: {
            ...prev[programIndex],
            [field]: errorMessage
          }
        }));
        value = null;
      } else {
        setTimeErrors(prev => ({
          ...prev,
          [programIndex]: {
            ...prev[programIndex],
            [field]: null
          }
        }));
        value = selectedDateTime;
      }
    }

    setDays(prevDays => {
      const newDays = [...prevDays];
      newDays[selectedDay] = {
        ...newDays[selectedDay],
        programSlots: newDays[selectedDay].programSlots?.map((program, index) =>
          index === programIndex ? { ...program, [field]: value } : program
        ),
      };
      return newDays;
    });
  };

  const handleTimeAccept = (programIndex, field) => {
    const error = timeErrors[programIndex]?.[field];
    if (error) {
      console.log("error: ", error);
    }
  };

  const handleAddProgram = () => {
    setInitialDay(initialDay + 1);
    setDays(prevDays => {
      const newDays = [...prevDays];
      const currentDay = newDays[selectedDay];
      const lastProgram = currentDay.programSlots[currentDay.programSlots.length - 1];
      
      let newStartTime = null;
      let newEndTime = null;

      if (lastProgram.endTime) {
        newStartTime = dayjs(lastProgram.endTime);
        newEndTime = newStartTime.add(1, 'hour');
      }

      newDays[selectedDay] = {
        ...currentDay,
        programSlots: [
          ...currentDay.programSlots,
          {
            eventId: eventData?.id,
            eventDayId: initialDay,
            speakerId: null,
            title: `Event Program ${currentDay.programSlots.length + 1}`,
            description: "",
            startTime: newStartTime,
            endTime: newEndTime,
            speaker: null
          },
        ],
      };
      return newDays;
    });
  };

  const handleRemoveProgram = () => {
    setDays(prevDays => {
      const newDays = [...prevDays];
      if (newDays[selectedDay].programSlots.length > 1) {
        newDays[selectedDay] = {
          ...newDays[selectedDay],
          programSlots: newDays[selectedDay].programSlots.slice(0, -1),
        };
        if (expandedProgram === newDays[selectedDay].programSlots.length) {
          setExpandedProgram(null);
        }
      }
      return newDays;
    });
  };

  const handleOpenModal = (programIndex) => {
    setOpenModal(true);
    setCurrentProgramIndex(programIndex);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedSpeaker(null);
  };

  const handleSelectSpeaker = (speaker) => {
    setSelectedSpeaker(speaker);
  };

  const handleConfirmSpeaker = () => {
    if (selectedSpeaker) {
      handleProgramInputChange(currentProgramIndex, "speaker", selectedSpeaker);
      handleCloseModal();
    }
  };

  const handleSaveDraft = () => {
    console.log("days: ", days);
  };

  const validateItinerary = () => {
    if (timeRangeError) {
      return { isValid: false, message: timeRangeError };
    }
    for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
      const day = days[dayIndex];
      if (!day.title.trim()) {
        return { isValid: false, message: `Day ${dayIndex + 1} title is missing` };
      }
      for (let slotIndex = 0; slotIndex < day.programSlots.length; slotIndex++) {
        const slot = day.programSlots[slotIndex];
        if (!slot.title.trim()) {
          return { isValid: false, message: `Program title is missing for Day ${dayIndex + 1}, Program ${slotIndex + 1}` };
        }
        if (!slot.startTime || !slot.endTime) {
          return { isValid: false, message: `Start or end time is missing for Day ${dayIndex + 1}, Program "${slot.title}"` };
        }
        if (!slot.speaker) {
          return { isValid: false, message: `Speaker is missing for Day ${dayIndex + 1}, Program "${slot.title}"` };
        }
        if (!slot.description) {
          return { isValid: false, message: `Description is missing for Day ${dayIndex + 1}, Program "${slot.title}"` };
        }
      }
    }
    return { isValid: true };
  };

  const handlePostItinerary = async () => {
    const validation = validateItinerary();
    if (!validation.isValid) {
      notification.error({
        message: "Validation Error",
        description: validation.message,
      });
      return;
    }

    try {
      const newPostedEventDays = [];

      for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
        const day = days[dayIndex];
        const dayStartTime = Math.floor(new Date(startDate.getTime() + dayIndex * 24 * 60 * 60 * 1000).getTime() / 1000);
        const dayEndTime = Math.floor(new Date(startDate.getTime() + (dayIndex + 1) * 24 * 60 * 60 * 1000).getTime() / 1000);
        
        const eventDayData = {
          eventId: eventData.id,
          name: day.title,
          startTime: dayStartTime,
          endTime: dayEndTime,
          dateAdded: Math.floor(new Date().getTime() / 1000),
        };

        const eventDayResponse = await eventDaysApi.createEventDay(eventDayData);
        if (eventDayResponse && eventDayResponse.id) {
          const postedDay = {
            ...eventDayResponse,
            programSlots: []
          };

          for (const slot of day.programSlots) {
            const slotData = {
              eventId: eventData.id,
              eventDayId: eventDayResponse.id,
              title: slot.title,
              description: slot.description,
              startTime: slot.startTime ? Math.floor(new Date(slot.startTime).getTime() / 1000) : null,
              endTime: slot.endTime ? Math.floor(new Date(slot.endTime).getTime() / 1000) : null,
              speakerId: slot.speaker.id,
              dateAdded: Math.floor(new Date().getTime() / 1000),
            };
            try {
              const slotResponse = await programSlotsApi.createProgramSlot(slotData);
              if (slotResponse && slotResponse.id) {
                postedDay.programSlots.push(slotResponse);
              } else {
                throw new Error("Failed to create program slot");
              }
            } catch (slotError) {
              console.error(`Error creating program slot:`, slotError);
              throw new Error(`Failed to create program slot for day ${dayIndex + 1}`);
            }
          }

          newPostedEventDays.push(postedDay);
        } else {
          throw new Error(`Failed to create event day ${dayIndex + 1}`);
        }
      }

      setPostedEventDays(newPostedEventDays);
      setItineraryPosted(true);
      console.log("Itinerary Posted Successfully");
      notification.success({
        message: 'Success',
        description: "Itinerary Posted Successfully",
      });
    } catch (error) {
      console.error("Error posting itinerary:", error);
      notification.error({
        message: "Error",
        description: error.message || "Failed to post itinerary. Please try again.",
      });
    }
  };

  useEffect(() => {
    if (initialEventDays && initialEventDays.length > 0 && 
        initialEventDays.every(day => day.programSlots && day.programSlots.length > 0)) {
      setPostedEventDays(initialEventDays);
      setItineraryPosted(true);
    }
  }, [initialEventDays]);

  return (
    <>
      {itineraryPosted ? (
        <PostedItineraryView eventData={eventData} eventDays={postedEventDays} speakersData={speakersData} />
      ) : (
        <Box display="flex">
        <Box display="flex" flexDirection="column" width="23%" padding="10px">
          {days?.map((day, index) => (
            <Button
              key={index}
              onClick={() => handleDayChange(index)}
              sx={{
                backgroundColor: index === selectedDay ? "#2D9687" : "#FFFFFF",
                color: index === selectedDay ? "#FFFFFF" : "#000000",
                marginBottom: "10px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: index === selectedDay ? "#2D9687" : "#FFFFFF",
                },
              }}
            >
              {day?.title}
            </Button>
          ))}
        </Box>

        <Box flex="1" padding="20px">
          <Typography variant="h6" fontWeight="bold" marginTop={-2}>
            Program details
            <span style={{color: "#2D9687", marginLeft: 15}}>
              {eventData?.eventName}
            </span>
          </Typography>
          <ThemeProvider theme={customTheme}>
          <Card variant="outlined" sx={{ marginTop: "10px" }}>
            <Box my={1} mx={2}>
              <Typography style={{fontSize: 15, fontWeight: "bold", color: "#575757"}}>
                Day {selectedDay + 1} Title
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                value={days[selectedDay]?.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
                margin="normal"
                padding="10px"
                backgroundColor="white"
                placeholder={`Enter day ${selectedDay + 1} title here`}
              />
            </Box>
          </Card>

          <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={4}>
            <Typography variant="h6" fontWeight="bold">
              Event Program
            </Typography>
            <Box>
              <IconButton onClick={handleRemoveProgram} color="primary" disabled={days[selectedDay]?.programSlots?.length <= 1}>
                <AiOutlineMinusCircle size={24} />
              </IconButton>
              <IconButton onClick={handleAddProgram} color="primary">
                <AiOutlinePlusCircle size={24} />
              </IconButton>
            </Box>
          </Box>
          {days[selectedDay]?.programSlots?.map((program, index) => (
            <Card variant="outlined" key={index} sx={{ marginTop: "10px", fontSize: 18, fontWeight: "bold", color: "#575757" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => handleExpandClick(index)}
                sx={{ cursor: "pointer" }}
              >
                <CardContent>{program?.title}</CardContent>
                <MdOutlineExpandMore
                  style={{
                    marginLeft: "20px",
                    fontSize: "30px",
                    color: "#575757",
                    transform: expandedProgram === index ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </Box>

              <Collapse in={expandedProgram === index}>
                <CardContent>
                  <Typography style={{fontSize: 15, fontWeight: "bold", color: "#575757"}}> 
                    Event Title
                  </Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    value={program?.title}
                    onChange={(e) => handleProgramInputChange(index, "title", e.target.value)}
                    margin="normal"
                    placeholder={`Enter event title here`}
                  />

                  <Typography style={{fontSize: 15, fontWeight: "bold", color: "#575757"}}> 
                    Event Description
                  </Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    value={program?.description}
                    onChange={(e) => handleProgramInputChange(index, "description", e.target.value)}
                    margin="normal"
                    placeholder={`Enter event description here`}
                  />
                  <div className="separator-horizontal" />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Starting time"
                          value={program?.startTime}
                          onChange={(newValue) => handleProgramInputChange(index, "startTime", newValue)}
                          onAccept={() => handleTimeAccept(index, "startTime")}
                          slotProps={{
                            textField: {
                              helperText: timeErrors[index]?.startTime,
                              error: !!timeErrors[index]?.startTime,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="End time"
                          value={program?.endTime}
                          onChange={(newValue) => handleProgramInputChange(index, "endTime", newValue)}
                          onAccept={() => handleTimeAccept(index, "endTime")}
                          slotProps={{
                            textField: {
                              helperText: timeErrors[index]?.endTime,
                              error: !!timeErrors[index]?.endTime,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(index)}
                    sx={{ marginTop: "10px" }}
                  >
                    {program.speaker ? "Change Speaker" : "Select Speaker"}
                  </Button>

                  {program?.speaker && (
                    <Box mt={2}>
                      <Typography variant="subtitle1">Selected Speaker:</Typography>
                      <Box display="flex" alignItems="center">
                        <img
                          src={program?.speaker.profilePic}
                          alt={program?.speaker.name}
                          width="40"
                          height="40"
                          style={{ borderRadius: "50%", marginRight: "10px" }}
                        />
                        <Typography>{program?.speaker.name}</Typography>
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Collapse>
            </Card>
          ))}

          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginRight: "10px" }}
              onClick={handleSaveDraft}
            >
              Save Draft
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePostItinerary}
            >
              Post Itinerary
            </Button>
          </Box>
          </ThemeProvider>
        </Box>

        <SpeakerModal
          open={openModal}
          onClose={handleCloseModal}
          speakersData={speakersData}
          selectedSpeaker={selectedSpeaker}
          onSelectSpeaker={handleSelectSpeaker}
          onConfirmSpeaker={handleConfirmSpeaker}
          customTheme={customTheme}
        />
      </Box>
      )}
    </>
  );
};

export default EventItineraryTab;