import React, { useState, useEffect } from "react";
import { Upload, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Input } from "rsuite";
import { IoMdClose } from "react-icons/io";
import { useForm } from "react-hook-form";
import caseStudyApi from "../../api/CaseStudyApi";
import utilApi from "../../api/UtilApi";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const customTheme = createTheme({
    palette: {
        primary: {
            main: '#2D9687',
        },
    },
});

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const CaseStudyEdit = ({ setDisplayCaseStudyView, caseStudyData }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(caseStudyData?.imageUrl || "");
    const [pdf1Url, setPdf1Url] = useState(caseStudyData?.pdf1Url || "");
    const [pdf2Url, setPdf2Url] = useState(caseStudyData?.pdf2Url || "");
    const [isDeleted, setIsDeleted] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    useEffect(() => {
        setFileList([{ url: caseStudyData?.imageUrl }]);
        setValue("imageUrl", caseStudyData?.imageUrl || "");
    }, [caseStudyData, setValue]);

    useEffect(() => {
        register("imageUrl", { 
            required: true,
            validate: value => value.trim() !== "" || "Image URL is required"
        });
        register("companyName", { required: true });
        register("companyDetails", { required: true });
        register("fullCaseStudy", { required: true });
        register("summaryCaseStudy", { required: true });
        register("videoUrl", { required: true });
        register("pdf1Url", { required: true });
        register("pdf2Url", { required: true });
    }, [register]);

    const handlePreview = async (file) => {
        const isSupportedFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp';
        if (!isSupportedFormat) {
            notification.error({
                message: 'Unsupported File Format',
                description: 'You can only upload JPEG/JPG/PNG/WEBP files.',
            });
            return;
        }
        
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        const baseString = file.preview.split(",")[1];
        const imageUrl = await utilApi.convertBase64ToPng(baseString);
        if (imageUrl) {
            notification.success({
                message: 'Image Uploaded',
                description: 'Image uploaded successfully!',
            });
            setPreviewImage(imageUrl);
        }
    };

    const handleImageChange = async ({ fileList }) => {
        setFileList(fileList);
        if (fileList.length > 0) {
            const file = fileList[fileList.length - 1];
            handlePreview(file);
        } else {
            setPreviewImage("");
            setValue("imageUrl", "");
        }
    };

    const handleRemove = () => {
        setFileList([]);
        setPreviewImage("");
    };

    const handlePdfUpload = (file, setPdfUrl, registerField) => {
        const isPdf = file.type === 'application/pdf';
        if (!isPdf) {
            notification.error({
                message: 'Unsupported File Format',
                description: 'You can only upload PDF files.',
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const splitBase64 = e.target.result.split(",")[1];
            const pdfUrl = await utilApi.convertBase64ToPdf(splitBase64);
            if (pdfUrl) {
                setPdfUrl(pdfUrl);
                registerField(pdfUrl);
                notification.success({
                    message: 'PDF Uploaded',
                    description: 'PDF uploaded successfully!',
                });
            } else {
                notification.error({
                    message: 'Upload Failed',
                    description: 'Failed to upload PDF.',
                });
            }
        };
        reader.readAsDataURL(file);
    };

    const onSubmit = async (data) => {
        if(isDeleteDialogOpen) return;
        if (isDeleted) return;

        if (!previewImage) {
            notification.error({
                message: 'Image Required',
                description: 'Please upload an image or provide an image URL before submitting.',
            });
            return;
        }

        const updatedCaseStudyData = {
            ...data,
            id: caseStudyData?.id,
            imageUrl: previewImage,
        };

        try {
            let response;
            if (caseStudyData) {
                response = await caseStudyApi.updateCaseStudy(caseStudyData.id, updatedCaseStudyData);
            } else {
                response = await caseStudyApi.createCaseStudy(updatedCaseStudyData);
            }

            if (response && response.success) {
                notification.success({
                    message: 'Success',
                    description: `Case study ${caseStudyData ? 'updated' : 'created'} successfully!`,
                });
                setTimeout(() => {
                    handleCloseCaseStudyView();
                }, 4000);
            } else {
                throw new Error('Operation failed');
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: `Failed to ${caseStudyData ? 'update' : 'create'} case study.`,
            });
        }
    };

    const showDeleteConfirmation = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        try {
            if (caseStudyData?.id) {
                const response = await caseStudyApi.deleteCaseStudy(caseStudyData.id);
                if (response.success) {
                    notification.success({
                        message: 'Success',
                        description: 'Case study deleted successfully!',
                    });
                    setIsDeleted(true);
                    setTimeout(() => {
                        handleCloseCaseStudyView();
                    }, 2000);
                } else {
                    throw new Error('Delete operation failed');
                }
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while deleting the case study.',
            });
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleCloseCaseStudyView = () => {
        setDisplayCaseStudyView(false);
    };

    return (
        <>
            <div className="flex flex-row">
                <IoMdClose
                    className="cursor-pointer"
                    size={18}
                    onClick={handleCloseCaseStudyView}
                />
                <div className="separator-vertical" />
                <span className="text-2xl font-bold">{caseStudyData ? "Edit Case Study" : "Post Case Study"}</span>
            </div>

            <div className="separator-horizontal" />

            <span className="text-gray-600">
                {caseStudyData ? "Update the case study details" : "Create case studies for your users to see"}
            </span>

            <div className="separator-horizontal" />
            <div className="separator-horizontal" />

            <form onSubmit={handleSubmit(onSubmit)}>
                <span className="font-bold">Cover</span>

                <div className="separator-horizontal" />

                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleImageChange}
                    onRemove={handleRemove}
                    beforeUpload={() => false}
                    showUploadList={false}
                >
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        {previewImage && (
                            <img
                                src={previewImage}
                                alt="Cover"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    opacity: 0.7,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                }}
                            />
                        )}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                color: 'white',
                                fontSize: '20px',
                                backgroundColor: previewImage ? 'rgba(0, 0, 0, 0.1)' : '#2d9687',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                zIndex: 2,
                            }}
                        >
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        </div>
                    </div>
                </Upload>
                <div style={{ width: "80%" }}>
                    <Input
                        {...register("imageUrl")}
                        value={previewImage}
                        onChange={(value) => {
                            setPreviewImage(value);
                            setValue("imageUrl", value);
                        }}
                        size="md"
                    />
                    {errors.imageUrl && <span style={{ color: 'red' }}>* This field is required</span>}
                </div>

                <div className="separator-horizontal" />

                <div style={{ width: "80%" }}>
                    <span className="font-bold">Company Name</span>
                    <div className="separator-horizontal" />
                    <Input
                        {...register("companyName", { required: true })}
                        defaultValue={caseStudyData?.companyName || ""}
                        size="md"
                    />
                    {errors.companyName && <span style={{ color: 'red' }}>* This field is required</span>}
                    <div className="separator-horizontal" />

                    <span className="font-bold">Company Details</span>
                    <div className="separator-horizontal" />
                    <Input
                        {...register("companyDetails", { required: true })}
                        defaultValue={caseStudyData?.companyDetails || ""}
                        size="md"
                    />
                    {errors.companyDetails && <span style={{ color: 'red' }}>* This field is required</span>}
                    <div className="separator-horizontal" />

                    <span className="font-bold">Full Case Study</span>
                    <div className="separator-horizontal" />
                    <Input
                        {...register("fullCaseStudy", { required: true })}
                        defaultValue={caseStudyData?.fullCaseStudy || ""}
                        size="md"
                    />
                    {errors.fullCaseStudy && <span style={{ color: 'red' }}>* This field is required</span>}
                    <div className="separator-horizontal" />

                    <span className="font-bold">Summary Case Study</span>
                    <div className="separator-horizontal" />
                    <Input
                        {...register("summaryCaseStudy", { required: true })}
                        defaultValue={caseStudyData?.summaryCaseStudy || ""}
                        size="md"
                    />
                    {errors.summaryCaseStudy && <span style={{ color: 'red' }}>* This field is required</span>}
                    <div className="separator-horizontal" />

                    <span className="font-bold">Video URL</span>
                    <div className="separator-horizontal" />
                    <Input
                        {...register("videoUrl", { required: true })}
                        defaultValue={caseStudyData?.videoUrl || ""}
                        size="md"
                    />
                    {errors.videoUrl && <span style={{ color: 'red' }}>* This field is required</span>}
                    <div className="separator-horizontal" />

                    <span className="font-bold">PDF 1 URL</span>
                    <div className="separator-horizontal" />
                    <Input
                        {...register("pdf1Url", { required: true })}
                        defaultValue={caseStudyData?.pdf1Url || ""}
                        size="md"
                    />
                    {errors.pdf1Url && <span style={{ color: 'red' }}>* This field is required</span>}
                    <div className="separator-horizontal" />
                    <div className="pdf-test-section" style={{ width: '45%', height: '60%' }}>
                        <input 
                            name="Upload PDF"
                            type="file" 
                            onChange={(e) => handlePdfUpload(e.target.files[0], setPdf1Url, (url) => setValue("pdf1Url", url))} 
                        />
                        <div className="separator-horizontal" />
                        {pdf1Url && (
                            <iframe src={pdf1Url} title="PDF 1" style={{ width: '100%', height: '100%' }} />
                        )}
                    </div>
                    <div className="separator-horizontal" />
                    <span className="font-bold">PDF 2 URL</span>
                    <div className="separator-horizontal" />
                    <Input
                        {...register("pdf2Url", { required: true })}
                        defaultValue={caseStudyData?.pdf2Url || ""}
                        size="md"
                    />
                    {errors.pdf2Url && <span style={{ color: 'red' }}>* This field is required</span>}
                    <div className="separator-horizontal" />
                    <div className="pdf-test-section" style={{ width: '45%', height: '60%' }}>
                        <input type="file" onChange={(e) => handlePdfUpload(e.target.files[0], setPdf2Url, (url) => setValue("pdf2Url", url))} />
                        <div className="separator-horizontal" />
                        {pdf2Url && (
                            <iframe src={pdf2Url} title="PDF 2" style={{ width: '100%', height: '100%' }} />
                        )}
                    </div>
                    <div className="separator-horizontal" />
                </div>
                <div className="separator-horizontal" />

                <div className="justify-end mt-3 mb-10">
                <div className="flex flex-row">
                    <button className="case-study-btn" type="submit" style={{color: "#2d9687", border: "2px solid", borderColor: "2d9687", borderRadius: "5px", padding: "3px"}}>
                        {caseStudyData ? "Update Case Study" : "Post Case Study"}
                    </button>

                    {caseStudyData && (
                        <button className="btnftn ml-3" onClick={showDeleteConfirmation}>
                            Delete Case Study
                        </button>
                    )}
                </div>
            </div>
            </form>

            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this case study?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ThemeProvider theme={customTheme}>
                    <Button variant="contained" onClick={handleDeleteCancel}>Cancel</Button>
                    <Button variant="outlined" onClick={handleDeleteConfirm}>
                        Yes, delete
                    </Button>
                    </ThemeProvider>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CaseStudyEdit;
