import React, { useState, useEffect } from "react";
import { Panel } from "rsuite";
import { IoAddOutline, IoPencilOutline } from "react-icons/io5";
import blogApi from "../../api/BlogApi";
import EditBlog from '../../components/blog-edit';
import "./styles.css";

const BlogsManage = () => {
  const [displayBlogView, setDisplayBlogView] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);

  const getBlogData = async () => {
    setLoading(true);
    const res = await blogApi.getPaginatedBlogData();
    if (res) {
      setBlogData(res.data.blogs);
    } else {
      console.log("failed");
    }
    setLoading(false);
  };

  useEffect(() => {
    getBlogData();
  }, [displayBlogView]);

  return (
    <div className="mb-8">
      {!displayBlogView ? (
        <>
          <div className="addButtonContainer">
            <button
              onClick={() => {
                setSelectedBlog(null);
                setDisplayBlogView(true);
              }}
              className="btnftn tab-button"
            >
              <IoAddOutline color="white" />
              Add blog
            </button>
          </div>

          <div className="columns-2 relative mt-5">
            {loading && <div className="loader">Loading...</div>}
            {blogData.map((blogItem) => (
              <Panel
                key={blogItem.id}
                shaded
                bordered
                bodyFill
                className="mb-3"
              >
                <button
                  onClick={() => {
                    setSelectedBlog(blogItem);
                    setDisplayBlogView(true);
                  }}
                  className="rounded-full w-[40px] h-[40px] m-2 flex absolute justify-center items-center bg-[#2D9687]"
                >
                  <IoPencilOutline size={20} color="white" />
                </button>
                <img
                  alt="blog banner"
                  src={blogItem.blogImage}
                  className="w-full"
                />
                <Panel className="h-[300px] overflow-y-auto" header={blogItem.blogTitle}>
                  <div
                    dangerouslySetInnerHTML={{ __html: blogItem.blogText }}
                  />
                </Panel>
              </Panel>
            ))}
          </div>
        </>
      ) : (
        <EditBlog blogData={selectedBlog} setDisplayBlogView={setDisplayBlogView} />
      )}
    </div>
  );
};

export default BlogsManage;
