import React from 'react';
import homepageVideo from '../../assets/videos/Homepage-BG.mp4'
// import homeSummitImage from '../../assets/images/Artboard-2.svg'
import footerLogo from '../../assets/images/Logos-in-one-line.png'
import { Navbar } from '../../components/navbar';
import './style.css'
import '../page-style.css'
import { useNavigate } from 'react-router-dom';


const HOME_TEXT = `The African Hidden Champions Initiative seeks out and showcases the unique growth stories of exciting African companies that are secretly building up the muscle of global leadership. 
The stories are focused on attracting local and international investments and growth support for these companies. `
// const HOME_SUMMIT_TEXT = `The Hidden Champions Summit is a unique and invite-only 
// opportunity for a carefully curated group of top executives from 30 
// well-established African hidden champion companies that are spearheading 
// their local economies. We aim to facilitate exclusive conversations with top 
// thought leaders throughout the summit to discuss the next course of action for such 
// leading businesses in the new economic environment of the post-2020 era.`

export const Landing = () => {
    const navigate = useNavigate();

    const handleEnter = ()=>{
        navigate("/about")
    }
    return (
        <div className={'main'}>
            <div className='home-overlay'></div>
            <Navbar whiteText={true} className={'home-navbar'} />
            <div className='home-text'>
                <div className={'home-text-inner'}> {HOME_TEXT}</div>
                <button className='home-btn-enter hover-green' onClick={handleEnter}>Enter</button>
            </div>
            <video src={homepageVideo} autoPlay muted loop className='video-contain' style={{height: 'inherit'}}></video>
            <div className='home-content-section'>
                <div>An Initiative By</div>
                <img src={footerLogo} alt={'summit'} className='home-afg-deg' />
            </div>

        </div>
    )
}