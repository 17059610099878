import React, { useState } from 'react';
import { Upload, Drawer, Button, notification } from 'antd';
import { Input } from "rsuite";
import { AiFillCamera, AiOutlinePlusCircle } from "react-icons/ai";
import utilApi from '../../api/UtilApi';

const SpeakerDrawer = ({ drawerVisible, setDrawerVisible, handleAddNewSpeaker, handleModalCancel }) => {
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [speakerDetails, setSpeakerDetails] = useState({
        profilePic: '',
        name: '',
        bio: '',
        linkedInUrl: '',
        image: '',
        videoLink: ''
    });

    const handleSpeakerChange = (field, value) => {
        setSpeakerDetails({
            ...speakerDetails,
            [field]: value
        });
    }

    const handleSpeakerSubmit = () => {
        if (!speakerDetails.name || !speakerDetails.bio) {
            notification.error({
                message: 'Incomplete Information',
                description: 'Please provide full name and bio before submitting.',
            });
            return;
        }

        if(speakerDetails.profilePic === ''){
            speakerDetails.profilePic = 'https://apploadstorage.blob.core.windows.net/apploadfiles/c6a9fb0f-c73b-411e-8368-b8230113fee3.jpeg';
        }
        
        handleAddNewSpeaker(speakerDetails);
        setDrawerVisible(false);
        setSpeakerDetails({
            profilePic: '',
            name: '',
            bio: '',
            linkedInUrl: '',
            videoLink: ''
        });
    }

    const handleCloseDrawer = () => {
        setDrawerVisible(false);
        handleModalCancel();
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleRemove = (type) => {
        setFileList({ ...fileList, [type]: [] });
        setSpeakerDetails({
            ...speakerDetails,
            [type]: ''
        });
    }

    const handleImageChange = async ({ fileList }) => {
        const isSupportedFormat = fileList[0].type === 'image/jpeg' || fileList[0].type === 'image/png' || fileList[0].type === 'image/jpg' || fileList[0].type === 'image/webp';
        if (!isSupportedFormat) {
            notification.error({
                message: 'Unsupported File Format',
                description: 'You can only upload JPEG/JPG/PNG/WEBP files.',
            });
            return;
        }
        setFileList(fileList);
        if (fileList.length > 0) {
            const file = fileList[fileList.length - 1];
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            const baseString = file.preview.split(",")[1];
            const imageUrl = await utilApi.convertBase64ToPng(baseString);
            if (imageUrl) {
                notification.success({
                    message: 'Success',
                    description: 'Image uploaded successfully!',
                });
                setPreviewImage(imageUrl);
                handleSpeakerChange('profilePic', imageUrl);
            }
        }
    };

    return (
        <Drawer
            title="Add Speaker"
            placement="right"
            onClose={handleCloseDrawer}
            visible={drawerVisible}
            width={700}
            headerStyle={{
                fontSize: "2.5rem",
                fontWeight: "bold"
            }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className="speaker-drawer-header">Speakers</div>
                    <button
                        type="primary"
                        onClick={handleSpeakerSubmit}
                        style={{ marginLeft: 'auto'}}
                    >
                        <AiOutlinePlusCircle style={{ fontSize: "25px", color: "green", borderColor: "white", marginTop: "-12px", marginRight: 5 }} />
                    </button>
                </div>

                <div className="speaker-drawer">
                    <span className="font-bold">Profile Image</span>
                    <div className="separator-horizontal" />
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleImageChange}
                        onRemove={handleRemove}
                        beforeUpload={() => false}
                        showUploadList={false}
                        style={{ width: '150px', height: '150px', margin: '0 auto' }}
                    >
                        {previewImage ? (
                            <img
                                src={previewImage}
                                alt="Event"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    color: 'white',
                                    backgroundColor: '#2d9687',
                                }}
                            >
                                <AiFillCamera style={{ fontSize: "28px" }} />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                    <div>
                        <Input
                            value={speakerDetails.profilePic}
                            onChange={(value) => handleSpeakerChange('profilePic', value)}
                            size="md"
                        />
                    </div>

                    <div className="separator-horizontal" />
                    <span className="font-bold">Full name</span>
                    <Input
                        value={speakerDetails.name}
                        onChange={(value) => handleSpeakerChange('name', value)}
                        size="md"
                    />

                    <div className="separator-horizontal" />
                    <span className="font-bold">Bio</span>
                    <Input
                        value={speakerDetails.bio}
                        onChange={(value) => handleSpeakerChange('bio', value)}
                        size="md"
                    />

                    <div className="separator-horizontal" />
                    <span className="font-bold">LinkedIn Link</span>
                    <Input
                        value={speakerDetails.linkedInUrl}
                        onChange={(value) => handleSpeakerChange('linkedInUrl', value)}
                        size="md"
                    />

                    <div className="separator-horizontal" />
                    <div style={{ flex: 1 }}>
                        <span className="font-bold">Video Link</span>
                        <Input
                            value={speakerDetails.videoLink}
                            onChange={(value) => handleSpeakerChange('videoLink', value)}
                            size="md"
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

export default SpeakerDrawer;
