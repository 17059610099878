import React from 'react';
import './style.css'
import '../page-style.css'
import { Navbar } from '../../components/navbar';
import { GiWorld } from 'react-icons/gi'
import { FaAward, FaGlobe, FaHandHolding } from 'react-icons/fa'
import AfricaMap from '../../assets/images/africa-map.png'
import degLogo from '../../assets/images/DEG-logo.png'
import afgLogo from '../../assets/images/AFG-logo.png'
import AfDBLogo from '../../assets/images/AfDB-Logo-AFD-Logo.svg'
import {Footer} from '../../components/footer'
import { useNavigate } from 'react-router-dom';
const WHY_1 = `Like Germany and other western nations, Africa is home to a fast-rising number of hidden champions - 
companies that are not well-known yet produce high-quality goods and provide world-class services that are globally competitive. `

const WHY_2 = `Africa has hundreds of fast-growing big companies but their often-remarkable success stories are largely unknown outside their home markets and rarely examined by international media or business experts and scholars.`

const WHY_3 = `The African Hidden Champions Initiative aims to close this gap by generating, publishing, and distributing case studies about such businesses; the initiative also includes an award and investment promotion scheme for the selected businesses.`

const AFRICA_COMPAINES = `AFG and DEG are key partners in the African Hidden Champions initiative. 
AFG leads outreach, data collection, and case generation. 
DEG is a project partner and a member of the project steering committee that guides the work’s creation and use. `

const OUR_PARTNERS_P1 = `Africa Foresight Group and DEG collaborated on 
the African Hidden Champions initiative to highlight outstanding African firms. 
AFG is in charge of data collection and case generation.`

const AFG_PARTNERS_1 = `For almost 60 years, the DEG has been a reliable partner to private-sector enterprises operating in developing and emerging-market countries. 
With a portfolio of around EUR 9 billion in approximately 80 countries, the DEG is one of the world’s largest private-sector development financiers. 
The DEG provides clients with tailor-made solutions, including financing, promotional programs, and advice required to develop successfully and create qualified jobs.  `

const AFG_PARTNERS_2 = ` Elevating African companies into global champions 

African-owned and majority female-led, AFG is a talent tech company that runs the largest talent network in Africa for bringing top flexible talent to companies through an in-house built software platform. 
Often dubbed “the uber of consulting” and “the African response to McKinsey”, AFG has delivered impact in more than 15 African markets with a pool of 250+ freelancers through more than 200 engagements across Africa`

const AFG_PARTNERS_3 = `It comprises three distinct entities: the African Development Bank (AfDB), the African Development Fund (ADF) and the Nigeria Trust Fund (NTF). On the ground in 44 African countries with an external office in Japan, the AfDB contributes to the economic development and the social progress of its 54 regional member states.`


export const About = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/join")
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className={'initiative-section'}>
                <div className='init-overlay'></div>
                <div className={'header-about-init'}>About the Initiative</div>
            </div>
            <div className='section-why'>
                <div className='why-text'>Why</div>
                <div className={'row'}>
                    <div className={'shadow-3 why-card'}>
                        <div className='why-number'> 1 </div>
                        <div className='page-text why-paragraph'>
                            {WHY_1}
                        </div>
                    </div>
                    <div className={'shadow-3 why-card'}>
                        <div className='why-number'> 2 </div>
                        <div className='page-text why-paragraph'>
                            {WHY_2}
                        </div>
                    </div>
                    <div className={'shadow-3 why-card'}>
                        <div className='why-number'> 3 </div>
                        <div className='page-text why-paragraph'>
                            {WHY_3}
                        </div>
                    </div>
                </div>
            </div>
            <div className='section-continent'>
                <div className='section-continent-left'>
                    <GiWorld className='world-icon' />
                    <p className='page-text continent-text'>
                        {AFRICA_COMPAINES}
                    </p>

                    <h2 className='h2-africa'>Top 3 reasons to focus on Africa’s growth</h2>
                    <ul>
                        <li className='page-text list-item'> Africa is a key frontier. It has the fastest-growing population and market </li>
                        <li className='page-text list-item'>Africa is home to the world’s largest free trade area </li>
                        <li className='page-text list-item'>Africa has 6 of the world’s 10 fastest-growing economies </li>
                    </ul>
                </div>
                <div className='section-continent-right'>
                    <img src={AfricaMap} alt='partner-logo' className='africa-map' />
                    <h2 className='h2-africa'>Africa is bigger than most people perceive</h2>
                </div>
            </div>

            <div className='section-partners'>
                <div className='partners-container our-partners-container'>
                    <div className='our-partners'>
                        Our Partners
                    </div>
                    <p className='page-text partners-text'>
                        {OUR_PARTNERS_P1}
                    </p>
                </div>

                <div className='partners-container partner-card'>
                    <img className='partner-logo' alt='partner-logo' src={degLogo} />
                    <h1 className='about-partner-header'>About DEG: More than finance</h1>
                    <p className='page-text partner-text'>
                        {AFG_PARTNERS_1}
                    </p>
                </div>
                <div className='partners-container partner-card'>
                    <img className='partner-logo' alt='partner-logo' src={afgLogo} />
                    <h1 className='about-partner-header'>About AFG: Elevating African people and companies into global champions</h1>
                    <p className='page-text partner-text'>
                        {AFG_PARTNERS_2}
                    </p>
                </div>
                    <div className='partners-container partner-card'>
                    <img className='partner-logo' alt='partner-logo' src={AfDBLogo} />
                    <h1 className='about-partner-header'>About AfDB: Africa’s premier development finance institution</h1>
                    <p className='page-text partner-text'>
                        {AFG_PARTNERS_3}
                    </p>
                </div>
            </div>
            <div className='section-roadmap dark-green-bg'>
                <div className='line-height-35'>
                    <span className='roadmap-text white'>Roadmap for a </span>
                    <span className='roadmap-text black'>Hidden </span><br />
                    <span className='roadmap-text black'>Champion company</span>
                </div>
                <div className='row'>
                    <div className='roadmap-container roadmap-card'>
                        <FaGlobe className='roadmap-icon' />
                        <p className='page-text roadmap-card-text'>
                           AFG and DEG select 1-2 high growth African companies to profile every quarter
                            The compendium of case studies of high growth African business are published on a dedicated website
                        </p>
                    </div>
                    <div className='roadmap-container roadmap-card'>
                        <FaAward className='roadmap-icon' />
                        <p className='page-text roadmap-card-text'>
                             Award to top annual hidden champion profiled in that particular year
                            To create additional coverage for the best company profiled
                        </p>
                    </div>
                    <div className='roadmap-container roadmap-card'>
                        <FaHandHolding className='roadmap-icon' />
                        <p className='page-text roadmap-card-text'>
                           DEG considers profiled companies for potential investment
                            Once a year, new case studies will be launched at an exclusive
                            online event to invite other investors to the table.
                        </p>
                    </div>
                </div>

            </div>
            <div className="section-participate">
                <div className="participate-text white">
                    Want to participate or partner with us?
                </div>
                <button className="btn btn-join" initiative onClick ={handleEnter}>Join the initiative <br/></button>
            </div>
            <Footer/>
        </div>
    )
}