import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {Home} from  "./pages/adminHome"
import {ContentManagement} from "./pages/adminContentManagement"


export const Dashroutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/adminContentManagement" element={<ContentManagement/>} />
    </Routes>
  )
}
