import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';

import Cleopatra_book_cover from '../../assets/images/Cleopatra-Book-Cover.png'
import CHG_logo from '../../assets/images/chg-logo.png';

import '../page-style.css'
import { useNavigate } from 'react-router-dom';
import Cleopatra_Final from '../../assets/downloads/Cleopatra-Hospitals-Group.pdf';

import {BsArrowLeft} from 'react-icons/bs';
const P1 = "Cleopatra Hospitals Group (CHG) was established in 2014 as a consolidated Group of healthcare providers with a very clearly defined strategy of building an institutionalised and integrated healthcare group within a highly underserved population."
const P2 = "CHG has grown through the acquisition of hospitals, either via full ownership or a majority stake, the establishment of long-term management/concession agreements with hospitals, and the launch of polyclinics and pharmacies."
// const P3 = "Building an Empire, One Snack at a time,consistently introducing new product propositions to best match consumers’ evolving tastes."

export const CLEOPATRA = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/case-studies")
    }

    const onButtonClick = (typeOfCase) => {
        fetch(typeOfCase).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = typeOfCase;
                alink.click();
            })
        })
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className='section-Study'>      
                <div className='study-img-card'>
                    <img src={CHG_logo} alt={'ubi'} className='ubi-enegry-image' /> 
                    <div className='ubi-text'>
                        Cleopatra Hospital Group
                    </div>
                    <div className= 'casestudy-text'> {P1}</div> 
                </div>

                <div className='card'>
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={Cleopatra_book_cover} />
                        <h1 className='case-study-header'>
                            Cleopatra: Full case study</h1>
                        <p className='case-page-text partner-text'>
                            {P2}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(Cleopatra_Final)}>Download full case study</button>
                    </div>
                    
                    
                </div>
            </div>

           <div className='section-stats'>
            <div className='stat'>
                <h1 className='stat-header'>
                     2014
                </h1>
                <p1 className="stat-text">
                     Year founded
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    2 
                </h1>
                <p1 className = "stat-text">
                    Polyclinics Launched
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                   5,000 
                </h1>
                <p1 className="stat-text" >
                     Employees
                </p1>
            </div>
           </div>
            <div className='doc'>
                <h1 className='doc-text'>Watch the documentary</h1>
            </div>
            <div className='vid-frame videoWrapper'>
               <iframe className='yt-vid' width="1450" height="815" src="https://www.youtube.com/embed/Pn0emaNJJ-I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture & muted=1" allowfullscreen></iframe>
            </div>
            <div className='back-to-casestudy'>
                 <button className="btn btn-back-to-casestudy" onClick={handleEnter}>
                    <div className='back-btns'>
                        <BsArrowLeft className='arrow'/>
                        <div className='back-text'>
                            Back to casestudy
                        </div>
                    </div>
                    </button>
            </div>
            <Footer />
        </div>
    )
}