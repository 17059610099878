import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';

import ubi_full_case from '../../assets/images/Pupkewitz-full.png'
import ubi_logo from '../../assets/images/Pupk-large.png'
import './style.css'
import '../page-style.css'
import { useNavigate } from 'react-router-dom';
import pup_full_case_study from '../../assets/downloads/AHC_Pupkewitz_case_study.pdf';
import pup_Summary from '../../assets/downloads/AHC_Pupkewitz_case_study_exec_summary.pdf';
import {BsArrowLeft} from 'react-icons/bs';

const P1 = "From its bootstrapped inception almost a century ago, the Pupkewitz Group now retails products such as building materials, electrical goods, motor vehicles, agricultural products, catering equipment amongst others, and a fintech offering via its divisions Pupkewitz MegaBuild, Pupkewitz MegaTech, Pupkewitz Motors, Kaap Agri Namibia, Pupkewitz Catering Supplies, and the Today Group."
const P2 = "Strong Alone, Unstoppable Together: Namibia’s Pupkewitz Group"
const P3 = "Strong Alone, Unstoppable Together: Namibia’s Pupkewitz Group"

export const PUPKEWITZ = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/case-studies")
    }
    const onButtonClick = (typeOfCase) => {
        fetch(typeOfCase).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = typeOfCase;
                alink.click();
            })
        })
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className='section-Study'>      
                <div className='study-img-card'>
                    <img src={ubi_logo} alt={'ubi'} className='ubi-enegry-image' /> 
                    <div className='ubi-text'>
                         Pupkewitz Group
                    </div>
                    <div className= 'casestudy-text'> {P1}</div> 
                </div>

                <div className='card'>
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>Pupkewitz Group: Full case study</h1>
                        <p className='case-page-text partner-text'>
                            {P2}
                        </p>
                        <button className='download-casestudy hover-black' onClick={() => onButtonClick(pup_full_case_study)}>Download full case study</button>
                    </div>
                    
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>Pupkewitz Group: Executive Summary</h1>
                        <p className='case-page-text partner-text'>
                            {P3}
                        </p>
                        <button className='download-casestudy hover-black' onClick={() => onButtonClick(pup_Summary)}>Download executive summary</button>
                    </div>
                    
                </div>
            </div>

           <div className='section-stats'>
            <div className='stat'>
                <h1 className='stat-header'>
                    1904
                </h1>
                <p1 className="stat-text">
                    History of business dates back to 1904
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    1700+
                </h1>
                <p1 className = "stat-text">
                    employees
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>2.3%
                </h1>
                <p1 className="stat-text" >
                    The Group alone contributes 2.3% to Namibia's GDP
                </p1>
            </div>
           </div>
            <div className='doc'>
                <h1 className='doc-text'>Video documentary coming soon</h1>
            </div>
            <div className='back-to-casestudy'>
                 <button className="btn btn-back-to-casestudy" onClick={handleEnter}>
                    <div className='back-btns'>
                        <BsArrowLeft className='arrow'/>
                        <div className='back-text'>
                            Back to casestudy
                        </div>
                    </div>
                    </button>
            </div>
            <Footer />
        </div>
    )
}