import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import '../page-style.css'
import { useNavigate } from 'react-router-dom';
import mfsCaseStudy from '../../assets/downloads/MFS.pdf';
import africa from '../../assets/images/africa.jpeg';
import {BsArrowLeft} from 'react-icons/bs';
const P1 = "MFS Africa was established in 2009 by Dare Okoudjou to address African payment system challenges. In rapid succession, he was joined by Mazunzo Chaponda (Chief Engineering Automation Officer), Sharon Welang (Regional Managing Director, Francophone sub-Saharan Africa) and Filip Nilsson made up the first group of employees and got the business up and running."
const P2 = "MFS Africa is building and operating a digital payment network that connects all African countries to each other and to the world."
// const P3 = "Building an Empire, One Snack at a time,consistently introducing new product propositions to best match consumers’ evolving tastes."

export const MFS = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/case-studies")
    }

    const onButtonClick = (typeOfCase) => {
        fetch(typeOfCase).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = typeOfCase;
                alink.click();
            })
        })
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className='section-Study'>      
                <div className='study-img-card'>
                 <img src={africa} alt={'ubi'} className='ubi-enegry-image' /> 
                    <div className='ubi-text'>
                        MFS Africa:Making Borders Matter Less
                    </div>
                    <div className= 'casestudy-text'> {P1}</div> 
                </div>

                <div className='card'>
                    <div className='study-container study-card'>
                        <h1 className='case-study-header'>
                            MFS: Full case study</h1>
                        <p className='case-page-text partner-text'>
                            {P2}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(mfsCaseStudy)}>Download full case study</button>
                    </div>
                    
                    
                </div>
            </div>

           <div className='section-stats'>
            <div className='stat'>
                <h1 className='stat-header'>
                     2009
                </h1>
                <p1 className="stat-text">
                     Year founded
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    500 million
                </h1>
                <p1 className = "stat-text">
                    Mobile wallets
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                   40+ 
                </h1>
                <p1 className="stat-text" >
                    countries operations
                </p1>
            </div>
           </div>
           <div className='doc'>
                <h1 className='doc-text'>Documentary coming soon</h1>
            </div>
            <div className='back-to-casestudy'>
                 <button className="btn btn-back-to-casestudy" onClick={handleEnter}>
                    <div className='back-btns'>
                        <BsArrowLeft className='arrow'/>
                        <div className='back-text'>
                            Back to casestudy
                        </div>
                    </div>
                    </button>
            </div>
            <Footer />
        </div>
    )
}