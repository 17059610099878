class PollApi {
    constructor() {
      this.apiUrl = `${process.env.REACT_APP_BASE_URL}/PollInformation`;
      this.questionUrl = `${process.env.REACT_APP_BASE_URL}/PollQuestionOption`;
    }
  
    async getPollInformation() {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}`, {
          method: "GET",
          headers: headers,
        });
  
        if (response.status >= 200 && response.status <= 300) {
          let polls = await response.json();
          return polls;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  
    async getPollInformationById(id) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}/${id}`, {
          method: "GET",
          headers: headers,
        });
  
        if (response.status >= 200 && response.status <= 300) {
          let polls = await response.json();
          return polls;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  
    async createPollInformation(pollInformation) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(pollInformation),
        });
  
        if (response.status >= 200 && response.status <= 300) {
          let polls = await response.json();
          return polls;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }

    async createPollOptions(pollOptions) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.questionUrl}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(pollOptions),
        });
  
        if (response.status >= 200 && response.status <= 300) {
          let polls = await response.json();
          return polls;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  
    async deletePollInformation(id) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}/${id}`, {
          method: "DELETE",
          headers: headers,
        });
  
        if (response.status >= 200 && response.status <= 300) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  }
  
  const pollApi = new PollApi();
  export default pollApi;
  