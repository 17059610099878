class SpeakerApi {
    constructor() {
      this.apiUrl = `${process.env.REACT_APP_BASE_URL}/Speaker`;
    }
  
    async getSpeakers() {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}`, {
          method: "GET",
          headers: headers,
        });
  
        if (response.status >= 200 && response.status <= 300) {
          let speakers = await response.json();
          return speakers;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    
    async getSpeakerById(id) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}/${id}`, {
          method: "GET",
          headers: headers,
        });
        
        if (response.status >= 200 && response.status <= 300) {
          let speakerData = await response.json();
          return speakerData;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    
    async createSpeaker(speakerData) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(speakerData),
        });
        
        if (response.status >= 200 && response.status <= 300) {
          let createdSpeaker = await response.json();
          return createdSpeaker;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    
    async updateSpeaker(speakerData) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}/${speakerData.id}`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(speakerData),
        });
        
        if (response.status >= 200 && response.status <= 300) {
          return { success: true };
        } else {
          return { success: false };
        }
      } catch (error) {
        return { success: false };
      }
    }
    
    async deleteSpeaker(id) {
      try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        let response = await fetch(`${this.apiUrl}/${id}`, {
          method: "DELETE",
          headers: headers,
        });
  
        if (response.status >= 200 && response.status <= 300) {
          return { success: true };
        } else {
          return { success: false };
        }
      } catch (error) {
        return { success: false };
      }
    }
  }
  
  const speakerApi = new SpeakerApi();
  export default speakerApi;
  