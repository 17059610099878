class EventDaysApi {
  constructor() {
    this.apiUrl = `${process.env.REACT_APP_BASE_URL}/EventDay`;
  }

  async getEventDays() {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventDays = await response.json();
        return eventDays;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getEventDayById(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventDay = await response.json();
        return eventDay;
      } else {
        return { data: null };
      }
    } catch (error) {
      console.log(error);
      return { data: null };
    }
  }

  async createEventDay(eventDayData) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(eventDayData),
      });

      if (response.status >= 200 && response.status <= 300) {
        let createdEventDay = await response.json();
        return createdEventDay;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async updateEventDay(eventDayData) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${eventDayData.id}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(eventDayData),
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }

  async deleteEventDay(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "DELETE",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }

  async getEventDaysByEventId(eventId) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/event/${eventId}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventDays = await response.json();
        return eventDays;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

const eventDaysApi = new EventDaysApi();
export default eventDaysApi;
