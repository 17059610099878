class BlogApi {
  constructor() {
    this.userApiUrl = `/userauth`;
    this.authKey = process.env.REACT_APP_AUTH_KEY;
  }

  getPaginatedBlogData = async () =>{
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", "basic ");
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Blog/paginated?pageNumber=${1}&pageSize=${50}&userId=${104}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        let blogTagResp = await response.json();
        return { data: blogTagResp };
      } else {
        return { data: null };
      }
    } catch (error) {
      console.log(error);
      return { data: null };
    }
  }

  postBlogData = async (blogData) => {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `basic ${this.authKey}`);
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Blog`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(blogData),
        }
      );

      if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return { data: null };
    }
  }

  updateBlogData = async (blogData) => {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `basic ${this.authKey}`);
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Blog/${blogData.id}`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(blogData),
        }
      );

      if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return null
    }
  }

  deleteBlogData = async (blogId) => {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `basic ${this.authKey}`);
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Blog/${blogId}`,
        {
          method: "DELETE",
          headers: headers,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        return true; 
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

const blogApi = new BlogApi();
export default blogApi;
