import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import ubi_full_case from '../../assets/images/UBI-Full-Case.png'
import ubi_logo from '../../assets/images/UBI-large.png'
import './style.css'
import '../page-style.css'
import { useNavigate } from 'react-router-dom';
import ubi_full_case_study from '../../assets/downloads/UBI-Full-Casestudy.pdf';
import ubi_Summary from '../../assets/downloads/UBI-Summary.pdf';
import {BsArrowLeft} from 'react-icons/bs';

const P1 = "Founded in 2007 by the industrious Salma Okonkwo, UBI Energy handles the sourcing, shipping, and financing of refined petroleum products.The firm is on course to build the largest solar farm in West Africa to produce energy for more than 50,000 homes."
const P2 = "The energy changemaker– How an entrepreneur shifted dynamics in the Ghanaian energy sector"
const P3 = "The energy changemaker– How an entrepreneur shifted dynamics in the Ghanaian energy sector"


export const CaseUBIEnergy = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/case-studies")
    }
    
    const onButtonClick = (typeOfCase) => {
        fetch(typeOfCase).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = typeOfCase;
                alink.click();
            })
        })
    }
    

    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className='section-Study'>      
                <div className='study-img-card'>
                    <img src={ubi_logo} alt={'ubi'} className='ubi-enegry-image' /> 
                    <div className='ubi-text'>
                        UBI Energy
                    </div>
                    <div className= 'casestudy-text'><p className='P1-text'>{P1}</p></div> 
                </div>

                <div className='card'>
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>UBI Energy: Full case study</h1>
                        <p className='case-page-text partner-text'>
                            {P2}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(ubi_full_case_study)}>Download full case study</button>
                    </div>
                    
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>UBI Energy: Executive Summary</h1>
                        <p className='case-page-text partner-text'>
                            {P3}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(ubi_Summary)}>Download executive summary</button>
                    </div>
                    
                </div>
            </div>

           <div className='section-stats'>
            <div className='stat'>
                <h1 className='stat-header'>
                    2007
                </h1>
                <p1 className="stat-text">
                     Year founded
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    100m
                </h1>
                <p1 className = "stat-text">
                     UBI hit $100m in turnover by end of 2009
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    100MW
                </h1>
                <p1 className="stat-text" >
                    Salma launched BluePower Energy to build the largest solar farm in West Africa with 100MW capacity
                </p1>
            </div>
           </div>
            <div className='doc'>
                <h1 className='doc-text'>Watch the documentary</h1>
            </div>
            <div className='vid-frame videoWrapper'>
                <iframe className='yt-vid' width="1450" height="815" src="https://www.youtube.com/embed/4FDGMIDqcZM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture & muted=1" allowfullscreen></iframe>
            </div>
            
            <div className='back-to-casestudy'>
                 <button className="btn btn-back-to-casestudy" onClick={handleEnter}>
                    <div className='back-btns'>
                        <BsArrowLeft className='arrow'/>
                        <div className='back-text'>
                            Back to casestudy
                        </div>
                    </div>
                    </button>
            </div>
            <Footer />
        </div>
    )
}