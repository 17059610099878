import React,{ useState } from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer'
import './style.css'
import '../page-style.css'
import { COUNTRIES_LIST } from '../../utils/countries'
import axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader";

const SECTORS = ['Construction', 'Consulting', 'Education', 'Finance',
    'Healthcare', 'Investment', 'Manufactiring', 'Technology', 'Transport and Logistics', 'Other']

const REVENUES = ['$10 - $19.9 million', '$20 - $29.9 million', '$30 - $39.9 million', '$40 - $49.9 million', 'Over $50 Million']

export const Join = () => {
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [comapany, setCompany] = React.useState('')
    const [country, setCountry] = React.useState('')
    const [sector, setSector] = React.useState('')
    const [other, setOther] = React.useState('')
    const [revenue, setRevenue] = React.useState('')
    const [website, setWebsite] = React.useState('')
    const [wish, setWish] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [loading, setLoading] = useState(false);
    const submitHandler = () => {
        const googleFormData = {
            Name: firstName,
            Last_Name: lastName,
            Email: email,
            Comapany: comapany,
            Country: country,
            Sector: sector,
            Other: other,
            Company_Revenue: revenue,
            Website: website,
            Wish_To_Participate: wish,
            Message: message
        }
        console.log(googleFormData);
        setLoading(true)
        axios.post('https://sheet.best/api/sheets/7cf6176d-de58-41d9-887a-6330f4704e0a', googleFormData)
            .then(response => {
                console.log(response);
            })
            .then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                setLoading(false)
            })
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className={'join-section'}>
                <div className='init-overlay'></div>
                <div className="join-header">Join The Initiative</div>
            </div>
            <div className='Tabs'>
            </div>
            <div className={'input-container'}>
                <div className='input-heading'>Want to participate in the initiative?</div>
                <div className='input-subheading'>We’d love to hear from you</div>

                <div className='inputs-contain'>
                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>First Name</div>
                            <input onChange={e => setFirstName(e.target.value)} placeholder='First Name' className='input' type='text'></input>
                        </div>
                        <div className='inputs-half'>
                            <div className='label'>Last Name</div>
                            <input onChange={e => setLastName(e.target.value)} placeholder='Last Name' className='input' type='text'></input>
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='inputs-full'>
                            <div className='label'>Email</div>
                            <input onChange={e => setEmail(e.target.value)} placeholder='Email' className='input' type='email'></input>
                        </div>
                    </div>

                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>Company</div>
                            <input onChange={e => setCompany(e.target.value)} placeholder='Company' className='input' type='text'></input>
                        </div>
                        <div className='inputs-half'>
                            <div className='label'>Country</div>
                            <select onChange={e => setCountry(e.target.value)} className='input' type='text'>
                                {COUNTRIES_LIST.map(country => (
                                    <option value={country}>{country}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>Choose Sector</div>
                            <select onChange={e => setSector(e.target.value)} className='input' type='text'>
                                {SECTORS.map(sector => (
                                    <option value={sector}>{sector}</option>
                                ))}
                            </select>
                        </div>
                        <div className='inputs-half'>
                            <div className='label'>If other, please state</div>
                            <input placeholder='Other' onChange={e => setOther(e.target.value)} className='input' type='text'></input>
                        </div>
                    </div>

                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>Company Revenue</div>
                            <select onChange={e => setRevenue(e.target.value)} className='input' type='text'>
                                {REVENUES.map(revenue => (
                                    <option value={revenue}>{revenue}</option>
                                ))}
                            </select>
                        </div>
                        <div className='inputs-half'>
                            <div className='label'>Company Website</div>
                            <input className='input' onChange={e => setWebsite(e.target.value)} placeholder='https://' type='text'></input>
                        </div>
                    </div>
                    <div>
                        <div className='label margin-top'>How do you wish to participate in the initiative?</div>
                        <div className='radio-container'>
                            <input className="radio" onChange={e => setWish(e.target.value)} type="radio" id="html" name="fav_language" value="Partner" />
                            <label for="html">Partner</label>
                        </div>
                        <div className='radio-container'>
                            <input className="radio" onChange={e => setWish(e.target.value)} type="radio" id="css" name="fav_language" value="Hidden Champion" />
                            <label for="css">Hidden Champion</label>
                        </div>
                    </div>
                    <div>
                        <div className='label margin-top'>
                            Please tell us why your company is interested in participating in the initiative
                        </div>

                        <textarea onChange={e => setMessage(e.target.value)} placeholder='Message' className='join-textarea' rows={10}></textarea>
                    </div>

                </div>
                {
                    loading ?
                        <ClipLoader color='#50A895' loading={loading} />
                        :
                        <button onClick={submitHandler} className='submit-champion hover-black'>Submit</button>
                }
            </div>
            <Footer />
        </div>
    )
}
