import React from 'react';
import { useNavigate } from 'react-router-dom';
import footerLogo from '../../assets/images/Logos-in-one-line.png'
import './style.css'

export const Footer = () => {
    const navigate = useNavigate(); 
    return (
        <div className={'footer'}>
            <div className={'footer-text'}></div>
            <div className={'footer-text'}>
                <button className='underLine' onClick={()=>navigate("/privacy")}>Privacy Policy</button>
                <br/>
                An Initiative By</div>
            <img src={footerLogo} alt={'summit'} className='home-afg-deg margin-10' />
        </div>
    )
}
export default Footer;