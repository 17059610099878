import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, notification } from "antd";
import { Input } from "rsuite";
import { useForm } from "react-hook-form";
import utilApi from '../../api/UtilApi';
import blogApi from "../../api/BlogApi";
import {
    EditorState,
    convertFromHTML,
    ContentState,
    AtomicBlockUtils
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#2D9687',
        },
    },
});

export const EditBlog = ({ setDisplayBlogView, blogData }) => {
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(blogData?.blogImage || "");
    const [editorState, setEditorState] = useState(() => {
        if (blogData?.blogText) {
            try {
                const blocksFromHTML = convertFromHTML(blogData.blogText);
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                return EditorState.createWithContent(contentState);
            } catch (e) {
                const contentState = ContentState.createFromText(blogData.blogText);
                return EditorState.createWithContent(contentState);
            }
        }
        return EditorState.createEmpty();
    });
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [htmlPreview, setHtmlPreview] = useState("");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    useEffect(() => {
        register("blogTitle", { required: true });
        register("blogImage", { required: true });
    }, [register]);

    useEffect(() => {
        if (blogData) {
            setValue("blogTitle", blogData.blogTitle);
            setValue("blogImage", blogData.blogImage);
            setPreviewImage(blogData.blogImage);
        }
    }, [blogData, setValue]);

    const handlePreview = async (file) => {
        const isSupportedFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp';
        if (!isSupportedFormat) {
            notification.error({
                message: 'Unsupported File Format',
                description: 'You can only upload JPEG/JPG/PNG/WEBP files.',
            });
            return;
        }

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        const baseString = file.preview.split(",")[1];
        const imageUrl = await utilApi.convertBase64ToPng(baseString);
        if (imageUrl) {
            setPreviewImage(imageUrl);
            setValue("blogImage", imageUrl);
            clearErrors("blogImage");
        }
    };

    const handleChange = async ({ fileList }) => {
        setFileList(fileList);
        if (fileList.length > 0) {
            const file = fileList[fileList.length - 1];
            await handlePreview(file);
        } else {
            setPreviewImage("");
            setValue("blogImage", "");
        }
    };

    const handleRemove = () => {
        setFileList([]);
        setPreviewImage("");
    };

    const onSubmit = async (data) => {
        try {
            const contentState = editorState.getCurrentContent();
            const htmlContent = stateToHTML(contentState);

            if (contentState.getPlainText().trim() === '') {
                notification.error({
                    message: 'Empty Blog Content',
                    description: 'The blog content cannot be empty. Please add some text to your blog.',
                });
                return;
            }

            if (!data.blogTitle || !htmlContent || !previewImage) {
                notification.error({
                    message: 'Incomplete Information',
                    description: 'Please fill in all required fields: Title, Body, and Cover Image.',
                });
                return;
            }

            const blogPostData = {
                userId: 104,
                blogTitle: data.blogTitle,
                blogText: htmlContent,
                blogImage: previewImage,
            };

            let response;
            if (blogData) {
                response = await blogApi.updateBlogData({ ...blogPostData, id: blogData.id });
            } else {
                response = await blogApi.postBlogData(blogPostData);
            }

            if (response) {
                notification.success({
                    message: 'Success',
                    description: `Blog ${blogData ? 'updated' : 'posted'} successfully!`,
                });
                setTimeout(() => {
                    handleCloseBlogView();
                }, 2000);
            }
        } catch (error) {
            console.error(`Error ${blogData ? 'updating' : 'posting'} blog:`, error);
            notification.error({
                message: 'Failure',
                description: `Failed to ${blogData ? 'update' : 'post'} blog.`,
            });
        }
    };

    const showDeleteConfirmation = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        await handleDeleteBlog();
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteBlog = async () => {
        try {
            if (blogData?.id) {
                const response = await blogApi.deleteBlogData(blogData.id);
                if (response) {
                    notification.success({
                        message: 'Success',
                        description: 'Blog deleted successfully!',
                    });
                    setTimeout(() => {
                        handleCloseBlogView();
                    }, 2000);
                }
            }
        } catch (error) {
            console.error("Error deleting blog:", error);
            notification.error({
                message: 'Failure',
                description: 'Failed to delete blog.',
            });
        }
    };

    const handleImageUpload = async (file) => {
        try {
            const base64 = await getBase64(file);
            const data = await utilApi.convertBase64ToPng(base64.split(",")[1]);

            if (data) {
                setEditorState((prevState) => {
                    const contentState = prevState.getCurrentContent();
                    const contentStateWithEntity = contentState.createEntity(
                        "IMAGE",
                        "IMMUTABLE",
                        { src: data }
                    );
                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                        prevState,
                        entityKey,
                        " "
                    );
                    return newEditorState;
                });
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            notification.error("Upload failed");
        }
    };

    const handleCloseBlogView = () => {
        setDisplayBlogView(false);
    };

    const handlePreviewBlog = () => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        setHtmlPreview(htmlContent);
        setIsPreviewVisible(true);
    };

    const handleBlogTextChange = (editorState) => {
        setEditorState(editorState);
    }

    const handleEmbeddedLink = (embeddedLink) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'EMBEDDED_LINK',
            'IMMUTABLE',
            { src: embeddedLink }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            { currentContent: contentStateWithEntity }
        );
        setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
    };

    return (
        <ThemeProvider theme={customTheme}>
            <>
                <div className="flex flex-row">
                    <IoMdClose
                        className="cursor-pointer"
                        size={18}
                        onClick={handleCloseBlogView}
                    />
                    <div className="separator-vertical" />
                    <text className="text-2xl font-bold">{blogData ? "Edit Blog" : "Add New Blog"}</text>
                </div>

                <div className="separator-horizontal" />

                <text className="text-gray-600">
                    {blogData ? "Modify the contents of your blog here" : "Create Blogs for your user to see"}
                </text>

                <div className="separator-horizontal" />
                <div className="separator-horizontal" />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <text className="font-bold">Cover</text>

                    <div className="separator-horizontal" />

                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        beforeUpload={() => false}
                        showUploadList={false}
                    >
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {previewImage && (
                                <img
                                    src={previewImage}
                                    alt="Cover"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        opacity: 0.7,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                    }}
                                />
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    color: 'white',
                                    fontSize: '20px',
                                    position: 'absolute',
                                    backgroundColor: previewImage ? 'rgba(0, 0, 0, 0.1)' : '#2d9687',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    zIndex: 2,
                                }}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            </div>
                        </div>
                    </Upload>
                    <div style={{ width: "80%" }}>
                        <Input
                            {...register("blogImage", { required: true })}
                            value={previewImage}
                            onChange={(value) => {
                                setPreviewImage(value);
                                setValue("blogImage", value);
                                if (value) {
                                    clearErrors("blogImage");
                                }
                            }}
                            size="md"
                        />
                        {errors.blogImage && <span style={{ color: 'red' }}>* This field is required</span>}
                    </div>

                    <div className="separator-horizontal" />

                    <div style={{ width: "80%" }}>
                        <text className="font-bold">Title</text>
                        <div className="separator-horizontal" />
                        <Input
                            {...register("blogTitle", { required: true })}
                            defaultValue={blogData?.blogTitle || ""}
                            onChange={(value) => setValue("blogTitle", value)}
                            size="md"
                        />
                        {errors.blogTitle && <span style={{ color: 'red' }}>* This field is required</span>}
                        <div className="separator-horizontal" />

                        <text className="font-bold">Body</text>
                    </div>

                    <div className="editorWrapper">
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleBlogTextChange}
                            placeholder="Write your blog content here..."
                            toolbar={{
                                options: ["inline", "blockType", "fontSize", "list", "textAlign", "history", "image", "link"],
                                embedded: {
                                    embedCallback: handleEmbeddedLink,
                                    defaultSize: {
                                        height: '315',
                                        width: '560',
                                    },
                                },
                                image: {
                                    uploadCallback: handleImageUpload,
                                    alt: { present: true, mandatory: false },
                                },
                            }}
                        />
                        {errors.blogText && <span style={{ color: 'red' }}>* This field is required</span>}
                    </div>

                    <div className="justify-end mt-3 mb-10">
                        <div className="flex flex-row">
                            <Button 
                                variant="contained" 
                                type="submit"
                                style={{ marginRight: '10px' }}
                            >
                                {blogData ? "Update Blog" : "Post Blog"}
                            </Button>

                            {blogData && (
                                <Button 
                                    variant="outlined" 
                                    onClick={showDeleteConfirmation} 
                                    style={{ marginRight: '10px' }}
                                >
                                    Delete Blog
                                </Button>
                            )}

                            <Button 
                                variant="outlined" 
                                onClick={handlePreviewBlog}
                            >
                                Preview Blog Text
                            </Button>
                        </div>
                    </div>
                </form>

                {isPreviewVisible && (
                    <div className="mobile-preview-container">
                        <div className="mobile-preview-header">
                            <div className="mobile-preview-notch" />
                        </div>
                        <div className="mobile-preview-content">
                            <div dangerouslySetInnerHTML={{ __html: htmlPreview }} />
                        </div>
                    </div>
                )}

                <Dialog
                    open={isDeleteDialogOpen}
                    onClose={handleDeleteCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Deletion"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this blog?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleDeleteCancel}>Cancel</Button>
                        <Button variant="outlined" onClick={handleDeleteConfirm} autoFocus>
                            Yes, delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </ThemeProvider>
    );
};

export default EditBlog;