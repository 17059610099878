import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function GalleryGrid({image_list}) {
    const [index, setIndex] = useState(-1);

    const currentImage = image_list[index];
    const nextIndex = (index + 1) % image_list.length;
    const nextImage = image_list[nextIndex] || currentImage;
    const prevIndex = (index + image_list.length - 1) % image_list.length;
    const prevImage = image_list[prevIndex] || currentImage;

    const handleClick = (index) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <div>
            <Gallery
                images={image_list}
                onClick={handleClick}
                margin={3}
                maxRows={3}
                enableImageSelection={false}
            />
            {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={currentImage.original}
                    imageTitle={currentImage.caption}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
        </div>
    );
}