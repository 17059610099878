class EventApi {
  constructor() {
    this.mainUrl = process.env.REACT_APP_BASE_URL;
    this.apiUrl = `${process.env.REACT_APP_BASE_URL}/Event`;
    this.authKey = process.env.REACT_APP_AUTH_KEY;
  }

  async getEvents() {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let event = await response.json();
        return event;
      } else {
        return { data: null };
      }
    } catch (error) {
      return { data: null };
    }
  }

  async getEventById(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventData = await response.json();
        return eventData;
      } else {
        return { data: null };
      }
    } catch (error) {
      return { data: null };
    }
  }

  async createEvent(eventData) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(eventData),
      });

      if (response.status >= 200 && response.status <= 300) {
        let createdEvent = await response.json();
        return createdEvent;
      } else {
        return { data: null };
      }
    } catch (error) {
      return { data: null };
    }
  }

  async updateEvent(eventData) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}/${eventData.id}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(eventData),
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }

  async deleteEvent(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "DELETE",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }

  async getEventAttendeesByEvent(eventId) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}Attendee/byEvent?eventId=${eventId}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventResp = await response.json();
        return eventResp;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getEventBookmarks() {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}Bookmark`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventResp = await response.json();
        return eventResp;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getCompanyMembers() {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.mainUrl}/CompanyMember`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventResp = await response.json();
        return eventResp;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return { data: null };
    }
  }

  async removeRsvp(data) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.mainUrl}/EventAttendee/toggleAttendance`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (response.status >= 200 && response.status <= 300) {
        let removedResponse = await response.json();
        return removedResponse;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return { data: null };
    }
  }

  async deleteAttendeeById(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.mainUrl}/EventAttendee/${id}`, {
        method: "DELETE",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }

  async deleteEventBookmarkById(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.mainUrl}/EventBookmark/${id}`, {
        method: "DELETE",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }

  async getEventInvitations() {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}Invites`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let eventResp = await response.json();
        return eventResp;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async addToAttendeeList(data) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}Invites`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (response.status >= 200 && response.status <= 300) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async sendEventInvitations(data) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      headers.set("Authorization", `Basic ${this.authKey}`);
      let response = await fetch(`${this.apiUrl}Invites/sendEventInviteByEmail?inviteeEmail=${data.inviteeEmail}&eventName=${data.eventName}&eventId=${data.eventId}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (response.status >= 200 && response.status <= 300) {
        return true
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

const eventApi = new EventApi();
export default eventApi;
