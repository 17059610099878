import React, { createContext, useContext, useState, useEffect } from "react";
import userApi from "../api/UserApi"
import { notification } from "antd";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  const [loginError, setLoginError] = useState(0);

  const login = async (email, password) => {
    setLoginError(0);
    try {
      const response = await userApi.loginUser(email, password);

      if (response.status) {
        const userRole = response.data.permissionLevel;
        
        if (userRole !== 1) {
          notification.error({
            message: "Login Failed",
            description: "You do not have permission to access this system.",
          });
          return false;
        }

        notification.success({
          message: "Login Successful",
          description: "You have successfully logged in",
        });
        const userEmail = response.data.emailAddress;
        console.log("Details: " + userRole, userEmail);

        localStorage.setItem("userRole", userRole);
        localStorage.setItem("userEmail", userEmail);

        setRole(userRole);

        return true;
      } else {
        if (response.message === "unauthorized") {
         
          notification.error({
            message: "Login Failed",
            description: "Invalid email or password",
          });
        } else {
          notification.error({
            message: "Something went wrong please try again.",
            description: "An error occurred",
          });
        }
        return false;
      }
    } catch (error) {
      console.error("Login error:", error.response.data.status);
      setLoginError(error.response.data.status);
      return false;
    }
  };

  useEffect(() => {
    let localRole = localStorage.getItem("userRole");
    console.log(localRole);
    if (localRole != null && localRole !== 'null') {
        // user is already logged in
        setRole(localRole);
    }
  }, []);

  const logout = () => {
    setRole(null);
    console.log(role);
    localStorage.setItem("userRole", null);
    localStorage.setItem("userEmail", null);
    // window.location.href = 'login'
  };

  return (
    <AuthContext.Provider value={{ role, setRole, login, logout, loginError }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
