import React, { useEffect, useState } from 'react';
import CaseStudyEdit from '../../components/case-study-edits/index';
import caseStudyApi from "../../api/CaseStudyApi";
import { Panel } from "rsuite";
import { IoPencilOutline, IoAddOutline } from "react-icons/io5";
import "../BlogsManage/styles.css";

const CaseStudyManage = () => {
  const [caseStudyData, setCaseStudyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayCaseStudyView, setDisplayCaseStudyView] = useState(false);
  const [selectedCaseStudy, setSelectedCaseStudy] = useState(null);

  const getCaseStudies = async () => {
    setLoading(true);
    const res = await caseStudyApi.getCaseStudies();
    if (res) {
      setCaseStudyData(res);
    } else {
      console.log("failed");
    }
    setLoading(false);
  };

  useEffect(() => {
    getCaseStudies();
  }, [displayCaseStudyView]);

  if (displayCaseStudyView) {
    return (
      <CaseStudyEdit
        caseStudyData={selectedCaseStudy}
        setDisplayCaseStudyView={setDisplayCaseStudyView}
      />
    );
  }

  return (
    <div className="mb-8">
        <div className="addButtonContainer">  
          <button onClick={() => {
            setDisplayCaseStudyView(true)
            setSelectedCaseStudy(null)
          }}
            className="btnftn tab-button">
            <IoAddOutline color="white" />
            Add Case Study
          </button>
        </div>
      <div className="grid grid-cols-2 gap-4 mt-5">

      {loading && <div className="loader">Loading...</div>}
      {caseStudyData?.map((caseStudyItem) => (
        <Panel
          key={caseStudyItem?.id}
          shaded
          bordered
          bodyFill
          className="relative"
          style={{ width: '100%', maxWidth: '400px' }}
        >
          <button 
            onClick={() => { 
              setSelectedCaseStudy(caseStudyItem); 
              setDisplayCaseStudyView(true); 
            }}
            className="rounded-full w-10 h-10 m-2 flex absolute top-0 right-0 justify-center items-center bg-[#2D9687]"
          >
            <IoPencilOutline size={20} color="white" />
          </button>
          <img
            alt="blog banner"
            src={caseStudyItem.imageUrl}
            className="w-full h-48 object-cover"
          />
          <Panel className="h-32 overflow-y-auto" header={caseStudyItem?.companyName}>
            <p>
              <small>
                {caseStudyItem?.companyDetails}
              </small>
            </p>
          </Panel>
        </Panel>
      ))}
    </div>
    </div>
  );
}

export default CaseStudyManage;
