class ProgramSlotsApi {
  constructor() {
    this.apiUrl = `${process.env.REACT_APP_BASE_URL}/ProgramSlot`;
  }

  async getProgramSlots() {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let programSlots = await response.json();
        return programSlots;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async getProgramSlotById(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let programSlotData = await response.json();
        return programSlotData;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async createProgramSlot(programSlotData) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(programSlotData),
      });

      if (response.status >= 200 && response.status <= 300) {
        let createdProgramSlot = await response.json();
        return createdProgramSlot;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async updateProgramSlot(id, programSlotData) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(programSlotData),
      });

      if (response.status >= 200 && response.status <= 300) {
        const updatedProgramSlot = await response.json();
        return updatedProgramSlot;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async deleteProgramSlot(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "DELETE",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }

  async getProgramSlotsByEventId(eventId) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/event/${eventId}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let programSlots = await response.json();
        return programSlots;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
}

const programSlotsApi = new ProgramSlotsApi();
export default programSlotsApi;
