import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';

import Niche_full_caseStudy from '../../assets/images/Niche-Book-Cover.png'
// import niche_logo from '../../assets/images/Niche_Cocoa.png';
import niche_logo from '../../assets/images/niche-logo.png';

import '../page-style.css'
import { useNavigate } from 'react-router-dom';
import Niche_Final from '../../assets/downloads/Niche-Final-Case.pdf';


import {BsArrowLeft} from 'react-icons/bs';
const P1 = "Founded in 2011 by former Wall Street banker Edmund Poku, Niche Cocoa Industry Ltd. manufactures high quality, semi-finished cocoa products for export, including cocoa liquor, cocoa butter, cocoa cake and cocoa powder."
const P2 = "Bringing Chocolate Home: Driving Local Economic Value Creation in the Global Chocolate Industry"
// const P3 = "Bringing Chocolate Home: Driving Local Economic Value Creation in the Global Chocolate Industry"

export const NICHE = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/case-studies")
    }

    const onButtonClick = (typeOfCase) => {
        fetch(typeOfCase).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = typeOfCase;
                alink.click();
            })
        })
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className='section-Study'>      
                <div className='study-img-card'>
                    <img src={niche_logo} alt={'ubi'} className='ubi-enegry-image' /> 
                    <div className='ubi-text'>
                        Niche Cocoa Industry
                    </div>
                    <div className= 'casestudy-text'> {P1}</div> 
                </div>

                <div className='card'>
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={Niche_full_caseStudy} />
                        <h1 className='case-study-header'>
                            Niche Cocoa: Full case study</h1>
                        <p className='case-page-text partner-text'>
                            {P2}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick( Niche_Final)}>Download full case study</button>
                    </div>
                    
                   
                    
                </div>
            </div>

           <div className='section-stats'>
            <div className='stat'>
                <h1 className='stat-header'>
                     2011
                </h1>
                <p1 className="stat-text">
                     Year founded
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    10% 
                </h1>
                <p1 className = "stat-text">
                    Ghana's Cocoa Processing 
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    60000 tons
                </h1>
                <p1 className="stat-text" >
                    Niche Cocoa is the largest fully integrated cocoa processor in Ghana
                </p1>
            </div>
           </div>
             <div className='doc'>
                <h1 className='doc-text'>Watch the documentary</h1>
            </div>
            <div className='vid-frame videoWrapper'>
               <iframe className='yt-vid' width="1450" height="815" src="https://www.youtube.com/embed/FJlkISFUfKw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture & muted=1" allowfullscreen></iframe>
            </div>
            <div className='back-to-casestudy'>
                 <button className="btn btn-back-to-casestudy" onClick={handleEnter}>
                    <div className='back-btns'>
                        <BsArrowLeft className='arrow'/>
                        <div className='back-text'>
                            Back to casestudy
                        </div>
                    </div>
                    </button>
            </div>
            <Footer />
        </div>
    )
}