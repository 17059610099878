import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';

import ubi_full_case from '../../assets/images/MSG-Full-case.png'
import ubi_logo from '../../assets/images/maghoub-logo.png'
import './style.css'
import '../page-style.css'
import { useNavigate } from 'react-router-dom';
import Maghoub_full_case_study from '../../assets/downloads/Maghoub-Full-casestudy.pdf';
import Maghoub_Summary from '../../assets/downloads/Maghoub-Summary.pdf';
import {BsArrowLeft} from 'react-icons/bs';

const P1 = "Over 6 decades, the Sudanese industrial giant has grown into more than 20 subsidiaries operating across the agriculture, manufacturing, food packaging, transport and media industries."
const P2 = "Building an integrated agriculture-focused business in Africa– The story of Mahgoub Sons Group"
const P3 = "Building an integrated agriculture-focused business in Africa– The story of Mahgoub Sons Group"

export const MAHGOUBSONS = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/case-studies")
    }

    const onButtonClick = (typeOfCase) => {
        fetch(typeOfCase).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = typeOfCase;
                alink.click();
            })
        })
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className='section-Study'>      
                <div className='study-img-card'>
                    <img src={ubi_logo} alt={'ubi'} className='ubi-enegry-image' /> 
                    <div className='ubi-text'>
                        Mahgoub Sons Group
                    </div>
                    <div className= 'casestudy-text'> {P1}</div> 
                </div>

                <div className='card'>
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>
                            Mahgoub Sons Group: Full case study</h1>
                        <p className='case-page-text partner-text'>
                            {P2}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(Maghoub_full_case_study)}>Download full case study</button>
                    </div>
                    
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>
                            Mahgoub Sons Group: Executive Summary</h1>
                        <p className='case-page-text partner-text'>
                            {P3}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(Maghoub_Summary)}>Download executive summary</button>
                    </div>
                    
                </div>
            </div>

           <div className='section-stats'>
            <div className='stat'>
                <h1 className='stat-header'>
                     1955
                </h1>
                <p1 className="stat-text">
                     Year founded
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    $100m+
                </h1>
                <p1 className = "stat-text">
                    from food exports only
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    200000
                </h1>
                <p1 className="stat-text" >
                    APCO operates on more than 200,000 acres of arable land across Sudn
                </p1>
            </div>
           </div>
            <div className='doc'>
                <h1 className='doc-text'>Watch the documentary</h1>
            </div>
            <div className='vid-frame videoWrapper'>
                <iframe className='yt-vid' width="1450" height="815" src="https://www.youtube.com/embed/JO51-Zn9abQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture & muted=1" allowfullscreen></iframe>
            </div>
            <div className='back-to-casestudy'>
                 <button className="btn btn-back-to-casestudy" onClick={handleEnter}>
                    <div className='back-btns'>
                        <BsArrowLeft className='arrow'/>
                        <div className='back-text'>
                            Back to casestudy
                        </div>
                    </div>
                    </button>
            </div>
            <Footer />
        </div>
    )
}