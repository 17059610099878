import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, IconButton } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Add, DeleteOutline } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import pollApi from "../../api/PollApi";

const PollModal = ({ visible, onClose, pollOptions, onOptionChange, onAddOption, onRemoveOption, onPollCreated, onRemovePoll }) => {
    const [expiryDate, setExpiryDate] = useState(null);
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [dateError, setDateError] = useState("");

    const validateFields = () => {
        let valid = true;
        let newErrors = {};

        if (!question.trim()) {
            newErrors.question = 'Question is required';
            valid = false;
        }

        if (!expiryDate) {
            setDateError("Expiry date is required");
        }else {
            setDateError("");
        }

        pollOptions.forEach((option, index) => {
            if (!option.pollOption.trim()) {
                newErrors[`option${index}`] = `Option ${index + 1} is required`;
                valid = false;
            }
        });

        setErrors(newErrors);
        return valid;
    };

    const handleCreatePoll = async () => {
        if (!validateFields()) return; 

        setLoading(true);
        const currentDate = Date.now();

        const pollEndDate = expiryDate ? new Date(expiryDate.getTime() + 24 * 60 * 60 * 1000) : null;

        const pollData = {
            addedBy: 104,
            pollQuestion: question,
            pollStatus: true,
            anonPoll: false,
            dateAdded: currentDate,
            activityId: null,
            pollEndDate: pollEndDate,
        };

        try {
            const response = await pollApi.createPollInformation(pollData);
            if (response && response.id) {
                const pollId = response.id;
                await createPollOptions(pollId, pollOptions, currentDate);
                onPollCreated();
                onClose();
                resetFields();
            }
        } catch (error) {
            console.error("Error creating poll:", error);
        } finally {
            setLoading(false);
        }
    };

    const createPollOptions = async (pollId, options, currentDate) => {
        for (let index = 0; index < options.length; index++) {
            const optionData = {
                pollOption: options[index].pollOption,
                pollId: pollId,
                addedBy: 104,
                optionValue: index + 1,
                dateAdded: currentDate,
            };

            try {
                await pollApi.createPollOptions(optionData);
            } catch (error) {
                console.error(`Error creating option ${index + 1}:`, error);
            }
        }
    };

    const handleDeletePoll = async (index, pollId) => {
        try {
            if (pollId) {
                await onRemovePoll(pollId);
            }
            onRemoveOption(index);
        } catch (error) {
            console.error("Error deleting poll:", error);
        }
    };

    useEffect(() => {
        if (pollOptions.length < 2) {
            onAddOption();
            onAddOption();
        }
    }, [pollOptions, onAddOption]);

    const isMaxOptions = pollOptions?.length >= 5;

    const handlePost = () => {
        handleCreatePoll();
    };

    const resetFields = () => {
        setExpiryDate(null);
        setQuestion('');
        onRemoveOption();
        pollOptions.forEach((option, index) => {
            onOptionChange(index, '');
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog open={visible} onClose={() => { onClose(); resetFields(); }} maxWidth="sm" fullWidth>
                <DialogTitle>Create new poll</DialogTitle>
                <DialogContent>
                    <p style={{ marginBottom: '1em' }}>All fields are required.</p>
                    
                    <div style={{ marginBottom: 16 }}>
                        <label htmlFor="expiry-date" style={{ fontSize: '14px', fontWeight: '500', display: 'block', marginBottom: '8px' }}>
                            Select Expiry Date -required
                        </label>
                        <DatePicker
                            id="expiry-date"
                            value={expiryDate}
                            onChange={(newValue) => setExpiryDate(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth error={!!errors.expiryDate} helperText={errors.expiryDate} />}
                            disablePast
                        />
                        {dateError && <p style={{ color: 'red', marginBottom: '16px' }}>{dateError}</p>}
                    </div>

                    <div style={{ marginBottom: 16 }}>
                        <label htmlFor="question" style={{ fontSize: '14px', fontWeight: '500', display: 'block', marginBottom: '8px' }}>
                            Question or Topic -required
                        </label>
                        <TextField
                            id="question"
                            placeholder="Enter question or topic"
                            fullWidth
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            error={!!errors.question}
                            helperText={errors.question}
                        />
                    </div>

                    {pollOptions?.map((option, index) => (
                        <div key={index} style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: '14px', marginRight: '16px' }}>Option {index + 1} -required</label>
                            <TextField
                                placeholder="Enter option"
                                value={option.pollOption || ''}
                                onChange={(e) => onOptionChange(index, e.target.value)}
                                fullWidth
                                error={!!errors[`option${index}`]}
                                helperText={errors[`option${index}`]}
                            />
                            {index >= 2 && (
                                <IconButton
                                    style={{ marginLeft: '8px', color: '#ff4d4f' }}
                                    onClick={() => handleDeletePoll(index, option.pollId)}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            )}
                        </div>
                    ))}

                    {isMaxOptions && (
                        <p style={{ color: 'red', marginBottom: '16px' }}>*Option limit reached</p>
                    )}

                    <Button
                        variant="outlined"
                        onClick={onAddOption}
                        disabled={isMaxOptions}
                        style={{ marginBottom: 16, color: isMaxOptions ? '#ccc' : '#2d9687', borderColor: isMaxOptions ? '#ccc' : '#2d9687' }}
                        startIcon={<Add />}
                    >
                        Add Another Option
                    </Button>

                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="outlined" onClick={() => { onClose(); resetFields(); }} style={{ marginRight: 8, color: "#2d9687", borderColor: "#2d9687" }}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#2d9687' }}
                            onClick={handlePost}
                            disabled={loading}
                        >
                            {loading ? 'Posting...' : 'Post'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </LocalizationProvider>
    );
};

export default PollModal;
