import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "../page-style.css";
import "./style.css";
import userApi from "../../api/UserApi";
import { useAuth } from "../../Provider/Authcontext";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Button, Checkbox, Form, Input } from "antd";

export const Login = () => {
  const { login } = useAuth();
  const nav = useNavigate();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();

  useEffect(() => {
    const emailInput = document.querySelector('input[name="emailAddress"]');
    const passwordInput = document.querySelector('input[name="password"]');

    if (emailInput && passwordInput) {
      const observer = new MutationObserver(() => {
        setValue("emailAddress", emailInput.value);
        setValue("password", passwordInput.value);
      });

      observer.observe(emailInput, { attributes: true, attributeFilter: ['value'] });
      observer.observe(passwordInput, { attributes: true, attributeFilter: ['value'] });

      return () => observer.disconnect();
    }
  }, [setValue]);

  const onSubmit = async (values) => {
    const { emailAddress, password } = values;
    try {
      const resp = await login(emailAddress, password);
      if (resp) {
        nav("/adminDashboard");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={"mainContainer"}>
      <div className={"innerContainer "}>
        <img
          className={"img"}
          src={require("../../assets/images/AHC-logo.png")}
          alt="img"
        />
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          className="form"
        >
          <Form.Item
            label="Email address"
            name="emailAddress"
            validateStatus={errors.emailAddress ? "error" : ""}
            help={errors.emailAddress?.message}
          >
            <Controller
              name="emailAddress"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
                maxLength: { value: 50, message: "Email must be 50 characters or less" },
              }}
              render={({ field }) => <Input {...field} maxLength={50} />}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            validateStatus={errors.password ? "error" : ""}
            help={errors.password?.message}
          >
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
                maxLength: { value: 20, message: "Password must be 20 characters or less" },
              }}
              render={({ field }) => <Input.Password {...field} maxLength={20} />}
            />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button htmlType="submit" className="buttonSubmit" style={{ color: 'white' }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Toaster />
    </div>
  );
};
