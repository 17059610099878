import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Divider,
    TextField,
} from "@mui/material";
import eventApi from "../../api/EventApi";

const InvitationModal = ({ invitations, eventData }) => {
    const [openInvitationModal, setOpenInvitationModal] = useState(false);
    const [updatedInvitations, setUpdatedInvitations] = useState([]);
    const [invitationCount, setInvitationCount] = useState(0);
    const [invitationRemovedMessage, setInvitationRemovedMessage] = useState("");
    const [inviteeEmail, setInviteeEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleOpenInvitationModal = () => {
        setOpenInvitationModal(true);
    };

    const handleCloseInvitationModal = () => {
        setOpenInvitationModal(false);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSendInvite = async (email) => {
        const data = {
            inviteeEmail: email,
            eventName: `${eventData.eventName} - ${eventData.description}`,
            eventId: eventData.id,
        };

        try {
            let response = await eventApi.sendEventInvitations(data);
            if (response) {
                const newInvitation = {
                    id: response.invitationId,
                    inviteeEmail: email,
                    status: 0,
                };
                setUpdatedInvitations([...updatedInvitations, newInvitation]);
                setInvitationCount(invitationCount + 1);
                setInvitationRemovedMessage(`Invitation sent successfully to ${email}.`);
                setInviteeEmail("");
                setEmailError("");
            } else {
                setEmailError(`Failed to send invitation to ${email}.`);
            }
        } catch (error) {
            console.log(error);
            setEmailError(`Failed to send invitation to ${email}.`);
            setInvitationRemovedMessage(`Failed to send invitation to ${email}.`);
        }
    };

    const handleManualInvite = () => {
        if (!inviteeEmail) {
            setEmailError("Email is required.");
            return;
        }

        if (!validateEmail(inviteeEmail)) {
            setEmailError("Please enter a valid email address.");
            return;
        }

        setEmailError("");
        handleSendInvite(inviteeEmail);
    };

    useEffect(() => {
        if (invitations) {
            setUpdatedInvitations(invitations);
            setInvitationCount(invitations.length);
        }
    }, [invitations]);

    return (
        <div className="mb-4">
            <Button variant="contained" style={{ backgroundColor: "#2D9687" }} onClick={handleOpenInvitationModal}>
                + Invite attendees
            </Button>

            <Dialog open={openInvitationModal} onClose={handleCloseInvitationModal} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <div>
                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                            Add new attendees
                        </Typography>
                        <Typography style={{ marginBottom: "10px" }}>An email will be sent to the user to download the app</Typography>
                    </div>
                </DialogTitle>

                {invitationRemovedMessage && (
                    <Typography variant="body1" style={{ textAlign: "center", color: "green", margin: 2 }}>
                        {invitationRemovedMessage}
                    </Typography>
                )}

                <DialogContent key={invitationCount}>
                    <Typography variant="h6" style={{ marginBottom: "10px" }}>Email</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter email to invite"
                        variant="outlined"
                        value={inviteeEmail}
                        onChange={(e) => setInviteeEmail(e.target.value)}
                        error={!!emailError}
                        helperText={emailError}
                        style={{ marginBottom: "20px" }}
                    />
                    <div className="mb-3 flex flex-end">
                        <Button onClick={handleCloseInvitationModal} variant="outlined" style={{ marginLeft: "auto", color: "#2D9687", borderColor: "#2D9687"}}>
                            Cancel
                        </Button>

                        <Button variant="contained" style={{ backgroundColor: "#2D9687", color: "white", marginLeft: 10 }} onClick={handleManualInvite}>
                            + Add Attendee
                        </Button>
                    </div>
                    <Divider style={{marginBottom: "20px"}}/>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default InvitationModal;
