import React, { useEffect, useState } from 'react';
import EventEdit from '../../components/event-edits/index.js';
import eventApi from "../../api/EventApi";
import { Panel } from "rsuite";
import moment from "moment";
import { IoPencilOutline, IoAddOutline, IoSadOutline } from "react-icons/io5";
import "../BlogsManage/styles.css";

const EventsManage = () => {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayEventView, setDisplayEventView] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const getEvents = async () => {
    setLoading(true);
    const res = await eventApi.getEvents();
    if (res) {
      setEventData(res);
    } else {
      console.log("Failed to fetch events.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, [displayEventView]);

  if (displayEventView) {
    return (
      <EventEdit
        eventData={selectedEvent}
        setDisplayEventView={setDisplayEventView}
      />
    );
  }

  const formatDate = (date) => {
    return moment.unix(date).format("ddd, DD MMM YYYY, HH:mm");
  }

  return (
    <div className="mb-8">
      <div className="addButtonContainer">
        <button 
          onClick={() => {
            setDisplayEventView(true);
            setSelectedEvent(null);
          }}
          className="btnftn tab-button"
        >
          <IoAddOutline color="white" />
          Add Event
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-5">
        {loading && <div className="loader">Loading...</div>}
        {eventData.length === 0 ? (
          <div className="no-events-message">
            <IoSadOutline className="icon" />
            <p>No events available</p>
          </div>
        ) : (
          eventData.map((eventItem) => (
            <Panel
              key={eventItem?.id}
              shaded
              bordered
              bodyFill
              className="relative"
              style={{ width: '100%', maxWidth: '400px' }}
            >
              <div className="flex justify-between items-center p-2 absolute top-0 left-3 right-0">
                <div
                  className={`w-3 h-3 rounded-full mr-2 ${
                    eventItem.eventStatus ? 'bg-green-500' : 'bg-red-500'
                  }`}
                />
                <button
                  onClick={() => {
                    setSelectedEvent(eventItem);
                    setDisplayEventView(true);
                  }}
                  className="rounded-full w-10 h-10 flex justify-center items-center bg-[#2D9687]"
                >
                  <IoPencilOutline size={20} color="white" />
                </button>
              </div>
              <img
                alt="event banner"
                src={eventItem.eventImage}
                className="w-full h-48 object-cover"
              />
              <Panel className="h-40 overflow-y-auto" header={eventItem?.eventName}>
                <p>
                  <small>Location: {eventItem?.eventLocation}</small>
                </p>
                <p>
                  <small>Start Date: {formatDate(eventItem?.startDate)}</small>
                </p>
                <p>
                  <small>End Date: {formatDate(eventItem?.endDate)}</small>
                </p>
              </Panel>
            </Panel>
          ))
        )}
      </div>
    </div>
  );
}

export default EventsManage;
