import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';

import ubi_full_case from '../../assets/images/Nova-Pioneer-Cover.png'
import ubi_logo from '../../assets/images/nova-large.png'
import './style.css'
import '../page-style.css'
import { useNavigate } from 'react-router-dom';
import Nova_full_case_study from '../../assets/downloads/AHC_Nova-Pioneer_Executive-Summary__Final.pdf';
import Nova_Summary from '../../assets/downloads/AHC_Nova-Pioneer_Executive-Summary__Final.pdf';
import {BsArrowLeft} from 'react-icons/bs';
const P1 = "Nova Pioneer is a Pan-African independent school network that seeks to prepare the next generation of leaders and innovators through world-class teaching methods with an emphasis on 21st century skills."
const P2 = "Reimagining Quality in Education: How Nova Pioneer is Developing Africa’s Next Generation of Leaders and Innovators"
const P3 = "Reimagining Quality in Education: How Nova Pioneer is Developing Africa’s Next Generation of Leaders and Innovators"

export const NOVAPIONNER = () => {
    const navigate = useNavigate();
    const handleEnter = ()=>{
        navigate("/case-studies")
    }
    const onButtonClick = (typeOfCase) => {
        fetch(typeOfCase).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = typeOfCase;
                alink.click();
            })
        })
    }
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className='section-Study'>      
                <div className='study-img-card'>
                    <img src={ubi_logo} alt={'ubi'} className='ubi-enegry-image' /> 
                    <div className='ubi-text'>
                        Nova Pioneer
                    </div>
                    <div className= 'casestudy-text'> {P1}</div> 
                </div>

                <div className='card'>
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>Nova Pioneer: Full case study</h1>
                        <p className='case-page-text partner-text'>
                            {P2}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(Nova_full_case_study)}>Download full case study</button>
                    </div>
                    
                    <div className='study-container study-card'>
                        <img className='book-pic' alt='book-pic' src={ubi_full_case} />
                        <h1 className='case-study-header'>Nova Pioneer: Executive Summary</h1>
                        <p className='case-page-text partner-text'>
                            {P3}
                        </p>
                        <button className='download-casestudy hover-green' onClick={() => onButtonClick(Nova_Summary)}>Download executive summary</button>
                    </div>
                    
                </div>
            </div>

           <div className='section-stats'>
            <div className='stat'>
                <h1 className='stat-header'>
                    2014
                </h1>
                <p1 className="stat-text">
                     Year founded
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    5000+
                </h1>
                <p1 className = "stat-text">
                    students
                </p1>
            </div>
            <div className='stat'>
                <h1 className='stat-header'>
                    14
                </h1>
                <p1 className="stat-text" >
                    schools across Africa
                </p1>
            </div>
           </div>
            <div className='doc'>
                <h1 className='doc-text'>Watch the documentary</h1>
            </div>
            <div className='vid-frame videoWrapper'>
                <iframe className='yt-vid' width="1450" height="815" src="https://www.youtube.com/embed/ebrzECxe67g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className='back-to-casestudy'>
                 <button className="btn btn-back-to-casestudy" onClick={handleEnter}>
                    <div className='back-btns'>
                        <BsArrowLeft className='arrow'/>
                        <div className='back-text'>
                            Back to casestudy
                        </div>
                    </div>
                    </button>
            </div>
            <Footer />
        </div>
    )
}