import React, { useEffect, useState } from 'react';
import './style.css';
import AHC_nav_logo from '../../assets/images/AHC-nav-logo.png'
import { useNavigate } from 'react-router-dom';
import Drawer from 'react-modern-drawer';
import { GiHamburgerMenu } from 'react-icons/gi'
import 'react-modern-drawer/dist/index.css'

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const navItems = [
    { name: "Home", path: "/", value: 0 },
    { name: "About", path: "/about", value: 1 },
    { name: "People", path: "/people", value: 2 },
    { name: "Case Studies", path: "/case-studies", value: 3 },
    // { name: "AHC Summit Frankfurt 2022", path: "/summit", value: 4 },
    { name: "Gallery", path: "/gallery", value: 5 },
    { name: "Join the initiative", path: "/join", value: 6 },
    { name: "Nominate", path: "/nominate", value: 7 },
];

export const Navbar = ({ whiteText }) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        windowSize.innerWidth > 1024 ?
            <NavbarLong whiteText={whiteText} />
            :
            <MobileNav />
    )
}

const MobileNav = () => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(0)

    const navigate = useNavigate();

    const onSelect = (index) => {
        localStorage.setItem("index", index)
        navigate(navItems[index].path)
    }

    useEffect(() => {
        setSelected(localStorage.getItem("index"));
    }, [])

    const showDrawer = () => {
        setOpen(true);
        console.log(open)
    };


    const toggleDrawer = () => {
        setOpen((prevState) => !prevState)
    }


    return (
        <>
            <div className='hamburger-menu'>
                <img alt={'Logo'} className='logo' src={AHC_nav_logo} />
                <button onClick={showDrawer}>
                    <GiHamburgerMenu />
                </button>

            </div>
            <Drawer title="Basic Drawer" className='drawer' direction="right" onClose={toggleDrawer} open={open}>
                {navItems.map((navItem, index) => (
                    <p
                        onClick={() => onSelect(index)} key={index}
                        className={`mobile-item-nav ${navItem?.path === navItems[selected]?.path ?
                            'mobile-item-nav-selected' : ''}`}>
                        {navItem.name}
                    </p>
                ))}
            </Drawer>
        </>
    )
}

const NavbarLong = ({ whiteText }) => {
    const [selected, setSelected] = useState(0)
    const navigate = useNavigate();


    const onSelect = (index) => {
        localStorage.setItem("index", index)
        navigate(navItems[index].path)
    }

    useEffect(() => {
        setSelected(localStorage.getItem("index"));
    }, [])

    return (
        <div className={'nav-container'}>
            <div className='logo-section'>
                <img alt={'Logo'} className='logo' src={AHC_nav_logo} />
            </div>
            <div className='nav-section'>
                {navItems.map((navItem, index) => (
                    <div onClick={() => onSelect(index)} key={index}
                        className={`nav-item-name 
                        ${whiteText ? 'white-text' : 'black-text'}

                        ${navItem?.path === navItems[selected]?.path ? 'nav-item-name-selected' : ''}`}>
                        {navItem.name}
                    </div>
                ))}
            </div>
        </div>
    )
}