import React, { useState, useEffect } from "react";
import { Typography, Card, Button as AntButton, Tag, notification } from "antd";
import { Button } from '@mui/material';
import no_polls from "../../assets/images/no-polls.png";
import PollModal from './PollModal';
import { DeleteOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import pollApi from "../../api/PollApi";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const { Title, Text } = Typography;

const ManagePollsTab = () => {
    const [activeFilter, setActiveFilter] = useState('All');
    const [polls, setPolls] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [pollOptions, setPollOptions] = useState([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [pollToDelete, setPollToDelete] = useState(null);

    useEffect(() => {
        const getPolls = async () => {
            try {
                const response = await pollApi.getPollInformation();
                setPolls(response);
            } catch (error) {
                console.error(error);
                notification.error({ message: "Failed to load polls" });
            }
        };

        getPolls();
    }, []);

    const refreshPolls = async () => {
        try {
            const response = await pollApi.getPollInformation();
            setPolls(response);
        } catch (error) {
            console.error(error);
            notification.error({ message: "Failed to refresh polls" });
        }
    };

    const handleCreatePoll = async () => {
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };
    
    const handleOptionChange = (index, value) => {
        const updatedOptions = [...pollOptions];
        updatedOptions[index].pollOption = value;
        setPollOptions(updatedOptions);
    };
    
    const addOption = () => {
        setPollOptions([...pollOptions, { pollOption: '' }]);
    };

    const handleRemoveOption = (index) => {
        const updatedOptions = pollOptions.filter((_, i) => i !== index);
        setPollOptions(updatedOptions);
    };

    const handleOpenConfirmDelete = (id) => {
        setPollToDelete(id);
        setConfirmDeleteOpen(true);
    };

    const handleCloseConfirmDelete = () => {
        setConfirmDeleteOpen(false);
        setPollToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await pollApi.deletePollInformation(pollToDelete);
            notification.success({ message: "Poll deleted successfully" });
            refreshPolls();
        } catch (error) {
            console.error("Error deleting poll:", error);
            notification.error({ message: "Failed to delete poll" });
        }
        handleCloseConfirmDelete();
    };

    const handleRemovePoll = async (id) => {
        try {
            await pollApi.deletePollInformation(id);
            notification.success({ message: "Poll deleted successfully" });
            refreshPolls();
        } catch (error) {
            console.error("Error deleting poll:", error);
            notification.error({ message: "Failed to delete poll" });
        }
    };

    const calculateTotalVotes = (options) => {
        return options.reduce((total, option) => total + option.pollResults.length, 0);
    };

    const renderPolls = () => {
        return polls
            .map(poll => {
                const isExpired = moment(poll.pollEndDate).isBefore(moment());
                return {
                    ...poll,
                    pollStatus: !isExpired
                };
            })
            .filter(poll => activeFilter === 'All' || (poll.pollStatus ? 'Active' : 'Expired') === activeFilter)
            .map(poll => (
                <Card key={poll.id} style={{ marginBottom: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ color: '#666', fontSize: '14px', display: 'flex', alignItems: 'center' }}>
                            <CalendarOutlined style={{ marginBottom: 2, marginRight: 2 }} /> Expiry Date: {moment(poll.pollEndDate).format('DD/MM/YYYY')}
                        </Text>
                        <Tag color={poll.pollStatus ? 'green' : 'default'}>
                            {poll.pollStatus ? 'Active' : 'Expired'}
                        </Tag>
                    </div>

                    <Title level={5} style={{ margin: '16px 0', color: '#333' }}>{poll.pollQuestion}</Title>

                    {poll.pollQuestionOptions.map((option, index) => {
                        const totalVotes = calculateTotalVotes(poll.pollQuestionOptions);
                        const optionVotes = option.pollResults.length;
                        const percentage = totalVotes > 0 ? (optionVotes / totalVotes) * 100 : 0;

                        return (
                            <div key={option.id} style={{ marginBottom: 12 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
                                    <Text style={{ color: '#333' }}>{index + 1}. {option.pollOption}</Text>
                                    <Text style={{ color: '#666' }}>{percentage.toFixed(0)}% ({optionVotes} votes)</Text>
                                </div>
                                <div style={{ backgroundColor: '#f0f0f0', borderRadius: 4, height: 24, overflow: 'hidden' }}>
                                    <div
                                        style={{
                                            width: `${percentage}%`,
                                            height: '100%',
                                            backgroundColor: '#5AACA8',
                                            transition: 'width 0.5s ease-in-out'
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button 
                            variant="outlined" 
                            color="error" 
                            startIcon={<DeleteOutlined />}
                            style={{ marginTop: 16, padding: '6px 16px', color: "#2D9687", borderColor: "#2D9687" }}
                            onClick={() => handleOpenConfirmDelete(poll.id)}
                        >
                            Delete
                        </Button>
                    </div>
                </Card>
            ));
    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <div>
                    {['All', 'Active', 'Expired'].map((filter) => (
                        <Button 
                            key={filter}
                            onClick={() => setActiveFilter(filter)}
                            style={{ 
                                marginRight: 8, 
                                backgroundColor: activeFilter === filter ? '#2D9687' : 'transparent', 
                                color: activeFilter === filter ? 'white' : '#333',
                                border: activeFilter === filter ? 'none' : '1px solid #d9d9d9',
                            }}
                        >
                            {filter}
                        </Button>
                    ))}
                </div>

                <Button 
                    onClick={handleCreatePoll}
                    style={{ 
                        backgroundColor: '#2D9687',
                        color: 'white',
                        border: 'none',
                        padding: '6px 16px',
                    }}
                >
                    Create new poll
                </Button>
            </div>

            {polls.length ? (
                renderPolls()
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={no_polls} alt="No polls" style={{ width: 300, height: 300, marginBottom: 20 }} />
                    <Title level={4} style={{ color: '#8A8A8A' }}>
                        No polls available
                    </Title>
                </div>
            )}

            <PollModal 
                visible={modalVisible} 
                onClose={handleCloseModal} 
                pollOptions={pollOptions} 
                onOptionChange={handleOptionChange} 
                onAddOption={addOption} 
                onRemoveOption={handleRemoveOption}
                onPollCreated={() => {
                    handleCloseModal();
                    notification.success({ message: "Poll created successfully" });
                    refreshPolls();
                }} 
                onRemovePoll={handleRemovePoll}
            />

            <Dialog open={confirmDeleteOpen} onClose={handleCloseConfirmDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this poll?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDelete} color="primary" variant="outlined" style={{ borderColor: '#2D9687', color: '#2D9687' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" variant="contained" style={{ backgroundColor: '#2D9687', color: 'white' }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ManagePollsTab;
