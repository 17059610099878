class CaseStudyApi {
  constructor() {
    this.apiUrl = `${process.env.REACT_APP_BASE_URL}/CaseStudies`;
  }

  async getCaseStudies() {
    try {
        let headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
        headers.set("Authorization", "Basic");
        let response = await fetch(`${this.apiUrl}`, {
          method: "GET",
          headers: headers,
        });

        if (response.status >= 200 && response.status <= 300) {
          let event = await response.json();
          return event;
        } else {
          return { data: null };
        }
    } catch (error) {
      return { data: null };
    }
  }

  async getCaseStudyById(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "GET",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        let caseStudy = await response.json();
        return caseStudy;
      } else {
        return { data: null };
      }
    } catch (error) {
      return { data: null };
    }
  }

  async createCaseStudy(caseStudy) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(caseStudy),
      });

      if (response.status >= 200 && response.status <= 300) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async updateCaseStudy(id, caseStudy) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(caseStudy),
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }

  async deleteCaseStudy(id) {
    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/${id}`, {
        method: "DELETE",
        headers: headers,
      });

      if (response.status >= 200 && response.status <= 300) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }
}
  
const caseStudyApi = new CaseStudyApi();
export default caseStudyApi;
  