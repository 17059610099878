import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import GalleryGrid from '../../components/gallery-grid'
import './style.css'
import '../page-style.css'
import { image_list_lagos,image_list_Jhb, image_list_Frankfurt_Summit } from "../../components/image-list";

export const Gallery = () => (
    <div className={'main'}>
        <Navbar whiteText={false} className={'home-navbar'} />
        <div className={'gallery-section'}>
            <div className='case-overlay'></div>
            <div className={'header-case'}>Gallery</div>
        </div>
        <h1 className={'gallery-header'}>AHC Johannesburg reception</h1>
        <div className={'grid-container'}>
                <GalleryGrid image_list={image_list_Jhb}/>
            </div> <h1 className={'gallery-header'}>AHC Lagos reception</h1>
        <div className={'grid-container'}>
                <GalleryGrid image_list={image_list_lagos}/>
            </div>
        <h1 className={'gallery-header'}>AHC Frankfurt Summit</h1>
            <div className={'grid-container'}>
                <GalleryGrid image_list={image_list_Frankfurt_Summit}/>
            </div>
        <Footer />
    </div>
)
