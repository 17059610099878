import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import summit_logo from '../../assets/images/summit-logo.svg';
import DEG_logo from '../../assets/images/DEG-logo.png';
import made_africa_logo from '../../assets/images/made-africa-logo.png';
import africa_no_filter_logo from '../../assets/images/africa-no-filter-logo.png';
import dawa_logo from '../../assets/images/dawa-logo.png';
import nova_logo from '../../assets/images/nova-logo.png';
import pupk_logo from '../../assets/images/pupk-logo.png';
import ubi_logo from '../../assets/images/ubi-logo.png';
import maghoub_logo from '../../assets/images/maghoub-logo.png';
import karm_logo from '../../assets/images/karm-logo.png';
import niche_logo from '../../assets/images/niche-logo.png';
import edita_logo from '../../assets/images/edita-logo.png';
import chg_logo from '../../assets/images/chg-logo.png';
import twiga_logo from '../../assets/images/twiga-logo.webp';
import sole_logo from '../../assets/images/sole-logo.png';
import lnb_logo from '../../assets/images/lnb-logo.jpeg';

import AFG_logo from '../../assets/images/AFG-logo.png'
import './style.css'
import '../page-style.css'

const P1 = `The Hidden Champions Summit is a unique and invite-only opportunity for a 
carefully curated group of top executives from 30 well-established African hidden 
champion companies that are spearheading their local economies. We aim to 
facilitate exclusive conversations with top thought leaders throughout the summit 
to discuss the next course of action for such leading businesses 
in the new economic environment of the post-2020 era.`

const P2 = `We are settled on anchoring the agenda to a host location 
in Germany to emphasize the global opportunity for African companies when 
it comes to investment and expansion. Essentially, our general objectives are to: 
`


export const Summit = () => {
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className={'green-section'}>
                <img className='summit-logo' src={summit_logo} alt='summit logo' />
            </div>
            <div class="videoWrapper">
            <iframe src="https://player.vimeo.com/video/798099350" title="Video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div className='paragraph-padding'>
                <div className='paragraph-width'>
                    <p className='green-text smaller-text bottom-margin'>
                        {P1}
                    </p>
                    <p className='page-text smaller-text'>
                        {P2}
                    </p>
                    <ul className='summit-list smaller-text'>
                        <li className='disc-list'>Connect hidden champions to like-minded leaders and top thinkers from Africa and beyond</li>
                        <li className='disc-list'>Illustrate future global growth opportunities that champions can drive from Africa</li>
                        <li className='disc-list'>Examine concrete business concepts that can be pursued as an outcome of the summit </li>
                        <li className='disc-list'>Foster networking and partnerships between African businesses and investors to capture these opportunities </li>
                    </ul>
                </div>
            </div>
            <div className="center">
            <h2 className='green-header bold'>Partners</h2>
            </div>
            <div className='hori-scroll'>
                <img alt={'degLogo'} style={{ height: '40px' }} className='partner-logo-scrollable' src={DEG_logo} />
                <img alt={'afgLogo'} style={{ height: '60px' }} className='partner-logo-scrollable' src={AFG_logo} />
                <img alt={'madeAfrica logo'} className='partner-logo-scrollable' src={made_africa_logo} />
                <img alt={"africa_no_filter_logo"} className='partner-logo-scrollable' src={africa_no_filter_logo} />
            </div>
            <h2 className='green-header bold center'>Participating Companies</h2>
            <div className='hori-scroll-spacing'>
                <img alt={'degLogo'} style={{ height: '40px' }} className='partner-logo-scrollable' src={dawa_logo} />
                <img alt={'afgLogo'} style={{ height: '60px' }} className='partner-logo-scrollable' src={nova_logo} />
                <img alt={'madeAfrica logo'} className='partner-logo-scrollable' src={pupk_logo} />
                <img alt={"africa_no_filter_logo"} className='partner-logo-scrollable' src={ubi_logo} />
                <img alt={"maghoub_logo"} className='partner-logo-scrollable' src={maghoub_logo} />
                <img alt={"karm_logo"} className='partner-logo-scrollable' src={karm_logo} />
                <img alt={"niche_logo"} className='partner-logo-scrollable' src={niche_logo} />
                <img alt={"edita_logo"} className='partner-logo-scrollable' src={edita_logo} />
                <img alt={"chg_logo"} className='partner-logo-scrollable' src={chg_logo} />
                <img alt={"twiga_logo"} className='partner-logo-scrollable' src={twiga_logo} />
                <img alt={"sole_logo"} className='partner-logo-scrollable' src={sole_logo} />
                <img alt={"lnb_logo"} className='partner-logo-scrollable' src={lnb_logo} />
            </div>
            <Footer />
        </div>
    )
}