import React from 'react';
import './style.css'
import '../page-style.css'
import { Navbar } from '../../components/navbar';
import {Footer} from '../../components/footer'; 
import { Card,Avatar  } from 'antd';
import 'antd/dist/antd.css';
import 'tailwindcss/tailwind.css';
import { ArrowRightOutlined } from '@ant-design/icons';

import Image_1 from '../../assets/images/Image1.png'
import Image_2 from '../../assets/images/Image2.jpeg'
import Image_3 from '../../assets/images/Image3.png'
import Image_4 from '../../assets/images/Image4.png'
import Image_5 from '../../assets/images/Image5.png'
import Image_6 from '../../assets/images/Image6.png'
import Image_7 from '../../assets/images/Image7.jpeg'
import Image_8 from '../../assets/images/Image8.jpeg'
import Image_9 from '../../assets/images/Image9.jpeg'


const data = [
  {
    name: 'Franziska Hollmann',
    header: 'Seed Partner',
    position: 'DEG + AHC Co-Founder',
    // color: 'yellow',
    avatar: Image_1,
  },
  {
    name: 'Yasmin Kumi',
    header: 'Lead Implementation',
    position: 'AFG + AHC Co-Founder',
    // color: 'red',
    avatar: Image_2,
  },
  {
    name: 'Prof Marc Ventresca',
    header: 'Independent Advisor',
    position: 'Oxford',
    // color: 'blue',
    avatar: Image_3,
  },
  {
    name: 'Dr Abdu Mukhtar',
    position: 'AfDB',
    avatar: Image_4,
  },
  {
    name: 'Terence McNamee',
    position: 'AFG',
    avatar: Image_5,
  },
  {
    name: 'Tshepidi Moremong',
    position: 'Africa50',
    avatar: Image_6,
  },
  {
    name: 'David Arndt',
    position: 'DEG',
    avatar: Image_7,
  },
  {
    name: 'Rasheeda Seghosime',
    position: 'AFG',
    avatar: Image_8,
  },
  {
    name: 'Jaco Maritz',
    position: 'How We Made it in Africa',
    avatar: Image_9,
  },
];

export const People = () => {
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className={'people-section'}>
                <div className='init-overlay'></div>
                <div className={'header-people'}>People</div>
            </div>
             <div className="p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {data.map((item, index) => (
          <Card key={index} className="rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-4">
              <Avatar size={100} src={item.avatar} />
              <ArrowRightOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
              <div>
                <h3>{item.name}</h3>
                <p className="text-gray-500">{item.position}</p>
              </div>
            </div>
            {index < 3 && (
              <div className={`p-2 rounded-t-lg card-header bg-${item.color}-500 text-white`}>
                <h3>{item.title}</h3>
              </div>
            )}
            <p>{item.description}</p>
          </Card>
        ))}
      </div>
    </div>
            <Footer/>
        </div>
    )
}
