import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import speakerApi from "../../../api/SpeakerApi";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const SpeakerModal = ({ open, onClose, speakersData, selectedSpeaker, onSelectSpeaker, onConfirmSpeaker, customTheme }) => {
  const [editingSpeaker, setEditingSpeaker] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [updatedSpeakers, setUpdatedSpeakers] = useState([]);

  const handleEdit = (speaker) => {
    setEditingSpeaker(speaker.id);
    setEditedData(speaker);
  };

  const handleSave = async () => {
    try {
      const updatedSpeaker = await speakerApi.updateSpeaker(editedData);
      setUpdatedSpeakers([...updatedSpeakers, updatedSpeaker]);
      setEditingSpeaker(null);
    } catch (error) {
      console.error("Error updating speaker:", error);
    }
  };

  const handleInputChange = (e, field) => {
    setEditedData({ ...editedData, [field]: e.target.value });
  };
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="speaker-selection-modal"
      aria-describedby="modal-to-select-a-speaker"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "85%",
        height: "80%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Select a Speaker
        </Typography>
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>LinkedIn</TableCell>
                  <TableCell>Video</TableCell>
                  <TableCell>Bio</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {speakersData?.map((speaker) => (
                  <TableRow 
                    key={speaker.id}
                    sx={{ backgroundColor: editingSpeaker === speaker.id ? 'rgba(45, 150, 135, 0.1)' : 'inherit' }}
                  >
                    <TableCell>
                      <Radio
                        style={{ color: "#2D9687" }}
                        checked={selectedSpeaker?.id === speaker.id}
                        onChange={() => onSelectSpeaker(speaker)}
                      />
                    </TableCell>
                    <TableCell>
                      <img
                        src={speaker.profilePic}
                        alt={speaker.name}
                        width="40"
                        height="40"
                        style={{ borderRadius: "50%" }}
                      />
                    </TableCell>
                    <TableCell>
                      {editingSpeaker === speaker.id ? (
                        <TextField
                          value={editedData.name}
                          onChange={(e) => handleInputChange(e, 'name')}
                        />
                      ) : (
                        speaker.name
                      )}
                    </TableCell>
                    <TableCell>
                      {editingSpeaker === speaker.id ? (
                        <TextField
                          value={editedData.linkedInUrl}
                          onChange={(e) => handleInputChange(e, 'linkedInUrl')}
                        />
                      ) : (
                        <a href={speaker.linkedInUrl} target="_blank" rel="noreferrer">
                          {speaker.linkedInUrl}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {editingSpeaker === speaker.id ? (
                        <TextField
                          value={editedData.videoLink}
                          onChange={(e) => handleInputChange(e, 'videoLink')}
                          width="40%"
                        />
                      ) : (
                        <a href={speaker.videoLink} target="_blank" rel="noreferrer">
                          {speaker.videoLink}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {editingSpeaker === speaker.id ? (
                        <TextField
                          value={editedData.bio}
                          onChange={(e) => handleInputChange(e, 'bio')}
                          multiline
                        />
                      ) : (
                        speaker.bio
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => editingSpeaker === speaker.id ? handleSave() : handleEdit(speaker)}>
                        {editingSpeaker === speaker.id ? <SaveIcon /> : <EditIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <ThemeProvider theme={customTheme}>
          <Box sx={{ mt: 2 }}>
            <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }}>Cancel</Button>
            <Button onClick={onConfirmSpeaker} variant="contained" color="primary">Confirm</Button>
          </Box>
        </ThemeProvider>
      </Box>
    </Modal>
  );
};

export default SpeakerModal;