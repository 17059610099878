export const image_list_lagos = [
    {
        src: "https://live.staticflickr.com/65535/52701638447_ea8f95c32f_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/DSCF0834.jpg",
        width: 300,
        height: 174,

    },
    {
        src: "https://live.staticflickr.com/65535/52702413699_088cc9c4ee_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/DSCF0817.jpg",
        width: 320,
        height: 212,
    },
    {
        src: "https://live.staticflickr.com/65535/52702645848_5601de7410_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/DSCF0794.jpg",
        width: 320,
        height: 212,
    },
    {
        src: "https://live.staticflickr.com/65535/52701635022_1a1d0982f8_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/AFG-Cocktail-Reception-Lagos-Nigeria-27.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52702570090_03f6c98a86_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/DSCF0743.jpg",
        width: 320,
        height: 183,
    },
    {
        src: "https://live.staticflickr.com/65535/52702151681_c2016cc8eb_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/DSCF0708.jpg",
        width: 500,
        height: 320,
    },
    {
        src: "https://live.staticflickr.com/65535/52701630427_fcb77089f1_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/AFG-Cocktail-Reception-Lagos-Nigeria-8.jpg",
        width: 320,
        height: 190,
    },
    {
        src: "https://live.staticflickr.com/65535/52702640608_cf2360f6e5_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/AFG-Cocktail-Reception-Lagos-Nigeria-20.jpg",
        width: 250,
        height: 148,
    },
    {
        src: "https://live.staticflickr.com/65535/52702641408_6b5854c079_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2021/11/AFG-Cocktail-Reception-Lagos-Nigeria-12.jpg",
        width: 320,
        height: 213,
    },

];
export const image_list_Frankfurt_Summit = [


    {
        src: "https://live.staticflickr.com/65535/52412716526_605276981a_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-165.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52413231268_80f134a84e_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-150.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52413231003_4c45b5debf_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-153.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52412207662_042e979a8b_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-138.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52413229638_2b5084bcd5_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-107.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52412215647_89cb3e7b7d_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-084.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52413247678_715cb20e2d_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-078.jpg",
        width: 280,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52413247293_f9c6ba16d6_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-116.jpg",
        width: 320,
        height: 213,
    },

    {
        src: "https://live.staticflickr.com/65535/52413247893_5759a9ace1_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-087.jpg",
        width: 300,
        height: 213,
    },

    {
        src: "https://live.staticflickr.com/65535/52413020754_389fe5ba91_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-087.jpg",
        width: 300,
        height: 213,
    },
    




]

export const image_list_Jhb = [


    {
        src: "https://live.staticflickr.com/65535/52702181301_3f813280e8_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-165.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52701659722_22e5e00775_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-150.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52702667323_87c4129e32_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-153.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52702179616_82f9596c02_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-138.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52702179486_b68bf682e2_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-107.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52702180126_c843656aaa_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-084.jpg",
        width: 320,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52702434179_ac8e91088f_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-078.jpg",
        width: 200,
        height: 213,
    },
    {
        src: "https://live.staticflickr.com/65535/52702432859_41a5bf24fb_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-116.jpg",
        width: 320,
        height: 213,
    },

    {
        src: "https://live.staticflickr.com/65535/52702178421_56437a3a84_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-087.jpg",
        width: 200,
        height: 213,
    },

    {
        src: "https://live.staticflickr.com/65535/52702665283_90837804bf_b.jpg",
        original: "https://africanhiddenchampions.com/wp-content/uploads/2022/03/african-hidden-champions-087.jpg",
        width: 200,
        height: 213,
    },
    




]


