import React, { useState } from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer'
import './style.css'
import '../page-style.css'
import { COUNTRIES_LIST } from '../../utils/countries'
import axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader";

const REVENUES = ['$10 - $19.9 million', '$20 - $29.9 million', '$30 - $39.9 million', '$40 - $49.9 million', 'Over $50 Million']

export const Nominate = () => {

    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [comapany, setCompany] = React.useState('')
    const [country, setCountry] = React.useState('')
    const [nominate, setNominate] = React.useState('')
    const [revenue, setRevenue] = React.useState('')
    const [website, setWebsite] = React.useState('')
    const [contact, setcontact] = React.useState('')
    const [contactEmail, setcontactEmail] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [loading, setLoading] = useState(false);

    const submitHandler = () => {

        const googleFormData = {
            Name: firstName,
            Last_Name: lastName,
            Email: email,
            Comapany: comapany,
            Country: country,
            Nominated_Company: nominate,
            Company_Revenue: revenue,
            Website: website,
            Contact_Person: contact,
            Contact_Email: contactEmail,
            Message: message
        }
        console.log(googleFormData);
        setLoading(true)
        axios.post('https://sheet.best/api/sheets/7cf6176d-de58-41d9-887a-6330f4704e0a', googleFormData)
            .then(response => {
                console.log(response);
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                setLoading(false)
            })
    }
    return (

        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className={'join-section'}>
                <div className='init-overlay'></div>
                <div className={'header-nominate'}>Nominate a Hidden Champion</div>
            </div>
            <div className={'input-container'}>
                <div className='input-heading'>Want to participate in the initiative?</div>
                <div className='input-subheading'>We’d love to hear from you</div>


                <div className='inputs-contain'>
                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>First Name</div>
                            <input onChange={e => setFirstName(e.target.value)} placeholder='First Name' className='input' type='text'></input>
                        </div>
                        <div className='inputs-half'>
                            <div className='label'>Last Name</div>
                            <input onChange={e => setLastName(e.target.value)} placeholder='Last Name' className='input' type='text'></input>
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='inputs-full'>
                            <div className='label'>Email</div>
                            <input onChange={e => setEmail(e.target.value)} placeholder='Email' className='input' type='email'></input>
                        </div>
                    </div>

                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>Your Company</div>
                            <input onChange={e => setCompany(e.target.value)} placeholder='Your Company' className='input' type='text'></input>
                        </div>
                        <div className='inputs-half'>
                            <div className='label'>Your Country</div>
                            <select onChange={e => setCountry(e.target.value)} className='input' type='text'>
                                {COUNTRIES_LIST.map(country => (
                                    <option value={country}>{country}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>Nominated Company</div>
                            <input onChange={e => setNominate(e.target.value)} placeholder='Your Company' className='input' type='text'></input>
                        </div>
                        <div className='inputs-half'>
                            <div className='label'>Company Revenue</div>
                            <select onChange={e => setRevenue(e.target.value)} className='input' type='text'>
                                {REVENUES.map(revenue => (
                                    <option value={revenue}>{revenue}</option>
                                ))}
                            </select>
                        </div>
                    </div>


                    <div className='input-row'>

                        <div className='inputs-half'>
                            <div className='label'>Company Website</div>
                            <input onChange={e => setWebsite(e.target.value)} className='input' placeholder='https://' type='text'></input>
                        </div>

                        <div className='inputs-half'>
                            <div className='label'>Contact Person</div>
                            <input onChange={e => setcontact(e.target.value)} placeholder='Name Of Contact Person At Nominated Company' className='input' type='text'></input>
                        </div>
                    </div>
                    <div className='input-row'>
                        <div className='inputs-half'>
                            <div className='label'>Contact Email</div>
                            <input onChange={e => setcontactEmail(e.target.value)} placeholder="Nominated Company's Contact Email" className='input' type='text'></input>
                        </div>

                    </div>

                    <div>
                        <div className='label margin-top'>
                            Please tell us why you are nominating this company to participate in the initiative
                        </div>

                        <textarea onChange={e => setMessage(e.target.value)} placeholder='Message' className='join-textarea' rows={10}></textarea>
                    </div>
                </div>
                {
                    loading ?
                        <ClipLoader color='#50A895' loading={loading} />
                        :
                        <button onClick={submitHandler} className='submit-champion hover-black'>Submit</button>
                }  
            </div>
            <Footer />
        </div>
    )
}
