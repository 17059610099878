import React, { useState } from "react";
import {
  Container,
  Sidebar,
  Sidenav,
  Nav,
} from "rsuite";
import DashboardIcon from "@rsuite/icons/Dashboard";
import ImageIcon from "@rsuite/icons/Image";
import "./style.css";
import "./container.less";
import OffIcon from "@rsuite/icons/Off";
import { Dashroutes } from "../../dashRoutes";
import {useNavigate } from "react-router-dom";
import { useAuth } from "../../Provider/Authcontext";

const headerStyles = {
  padding: 18,
  fontSize: 16,
  height: 100,
  display: "flex",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const Dashboard = () => {
  const nav = useNavigate();
  const [expand, setExpand] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const { logout } = useAuth();

  const HandleLogout = async () => {
    await logout();
    nav("/admin");
  };
  const handleSelect = (eventKey) => {
    if (eventKey !== activeKey) {
      setActiveKey(eventKey);
    }
  };

  const handleNavItemClick = (path) => {
    nav(path);
  };

  return (
    <div className="show-fake-browser sidebar-page" style={{overflow:"hidden"}}>
      <Container>
        <Sidebar
          style={{ display: "flex", flexDirection: "column" }}
          width={expand ? 260 : 56}
          collapsible
        >
          <Sidenav.Header>
            <div style={headerStyles}>
              <img
                className={"imgDash"}
                src={require("../../assets/images/AHC-logo-cut.png")}
                alt="logo"
              />
            </div>
          </Sidenav.Header>
          <Sidenav
            expanded={expand}
            defaultOpenKeys={["1"]}
            appearance="subtle"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Sidenav.Body>
              <Nav>
                <Nav.Item
                  className="navitemside"
                  eventKey="1"
                  icon={<DashboardIcon />}
                  onClick={() => handleNavItemClick("/adminDashboard")}
                >
                  Home
                </Nav.Item>
                <Nav.Item
                  onClick={() =>
                    handleNavItemClick("/adminDashboard/adminContentManagement")
                  }
                  className="navitem"
                  eventKey="2"
                  icon={<ImageIcon />}
                >
                  Content Management
                </Nav.Item>
                <Nav.Item
                  className="navitem"
                  onClick={HandleLogout}
                  eventKey="3"
                  icon={<OffIcon />}
                >
                  Logout
                </Nav.Item>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
          <div
            style={{
              marginTop: "auto",
              width: "100%",
              alignItems: "center",
              height: "10%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>AFG Privacy Policy</div>
            <div>Terms of Use</div>
            <div>© 2024 AHC by AFG</div>
          </div>
        </Sidebar>

        <Container
          style={{
            backgroundColor: "#F8F9FA",
            minHeight: "100vh",
            paddingLeft: "3%",
            paddingTop: "3%",
          }}
        >
          <Dashroutes />
        </Container>

        
      </Container>
    </div>
  );
};
