import React, { useState, useEffect } from "react";
import { Tabs } from "rsuite";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import style from "./style.css";
import speakerApi from "../../api/SpeakerApi";
import eventDaysApi from "../../api/EventDaysApi";
import EventItineraryTab from "../EventTabs/EventItineraryTab";
import ManageAttendeesTab from "../EventTabs/ManageAttendeesTab";
import EventDetailsTab from "../EventTabs/EventDetailsTab";

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const EventEdit = ({ setDisplayEventView, eventData }) => {
    const [speakersData, setSpeakersData] = useState([]);
    const [eventDays, setEventDays] = useState([]);

    const retrieveSpeakers = async () => {
        const response = await speakerApi.getSpeakers();
          if (response) {
            setSpeakersData(response);
        }
    };
    
    useEffect(() => {
        const retrieveEventDays = async () => {
            if(!eventData) return
            const response = await eventDaysApi.getEventDaysByEventId(eventData.id);
            if (response) {
                setEventDays(response);
            }
        };
    
        retrieveSpeakers();
        retrieveEventDays();
    }, []);
 
    return (
        <div className="flex flex-row justify-between w-11/12 h-12 items-center">
            <Tabs 
                defaultActiveKey="1" 
                appearance="subtle"
                className="w-full h-full tab-content"
            >
                <Tabs.Tab title="Event Details" eventKey="1">
                    <EventDetailsTab setDisplayEventView={setDisplayEventView} eventData={eventData} speakersData={speakersData}/> 
                </Tabs.Tab>
                <Tabs.Tab title="Event Itinerary" eventKey="2" disabled={!eventData}>
                    <EventItineraryTab eventData={eventData} speakersData={speakersData} eventDays={eventDays} />
                </Tabs.Tab>
                <Tabs.Tab title="Manage Attendees" eventKey="3" disabled={!eventData}>
                    <ManageAttendeesTab eventData={eventData} />
                </Tabs.Tab>
            </Tabs>
        </div>
    );
};

export default EventEdit;
