import React, { useState, useEffect } from "react";
import { Table, Input, Typography, Button, Avatar, Space, Pagination, Row, Col, Radio, Popover, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import userApi from "../../../api/UserApi";
import InvitationModal from "../../InvitationModal";
import eventApi from "../../../api/EventApi";
import { IoMdInformationCircleOutline } from "react-icons/io";

const { Title } = Typography;

const ManageAttendeesTab = ({ eventData }) => {
    const [invitations, setInvitations] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [attendees, setAttendees] = useState([]);
    const [eventId, setEventId] = useState(eventData?.id || "");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [viewMode, setViewMode] = useState("attendees");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredInvitations, setFilteredInvitations] = useState([]);

    useEffect(() => {
        const attendeeEmails = new Set(attendees.map(a => a.attendeeEmail.toLowerCase()));

        const sorted = allUsers.toSorted((a, b) => {
            const aIsAttendee = attendeeEmails.has(a.email.toLowerCase());
            const bIsAttendee = attendeeEmails.has(b.email.toLowerCase());
            return bIsAttendee - aIsAttendee;
        });

        const filtered = sorted.filter(user =>
            `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredUsers(filtered);
    }, [allUsers, attendees, searchTerm]);

    useEffect(() => {
        if (viewMode === 'attendees') {
            const attendeeEmails = new Set(attendees.map(a => a.attendeeEmail.toLowerCase()));
            
            const sorted = allUsers.toSorted((a, b) => {
                const aIsAttendee = attendeeEmails.has(a.email.toLowerCase());
                const bIsAttendee = attendeeEmails.has(b.email.toLowerCase());
                return bIsAttendee - aIsAttendee;
            });
    
            const filtered = sorted.filter(user =>
                `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
            );
    
            setFilteredUsers(filtered);
        } else {
            const filtered = invitations.filter(invite =>
                invite.inviteeEmail.toLowerCase().includes(searchTerm.toLowerCase())
            );
    
            setFilteredInvitations(filtered);
        }
    }, [allUsers, attendees, invitations, searchTerm, viewMode]);

    useEffect(() => {
        const getAttendees = async () => {
            try {
                const response = await eventApi.getEventAttendeesByEvent(eventId);
                setAttendees(response);
            } catch (error) {
                console.log("Error fetching event attendees: ", error);
            }
        };

        const getInvitations = async () => {
            try {
                const response = await eventApi.getEventInvitations();
                const filteredInvitations = response.filter(invite => invite.eventId === eventId);
                setInvitations(filteredInvitations);
            } catch (error) {
                console.log("Error fetching event invitations: ", error);
            }
        };

        const getAllUsers = async () => {
            try {
                const response = await userApi.getAllUsers();
                if (response.data) {
                    const users = response?.data.map((user) => ({
                        key: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.emailAddress,
                        profileImage: user.profilePic,
                    }));
                    setAllUsers(users);
                }
            } catch (error) {
                console.log("Error fetching all users: ", error);
            }
        };

        if (eventId) {
            getAttendees();
            getInvitations();
            getAllUsers();
        }
    }, [eventId]);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleAddAttendee = async (user) => {
        let data;

        if (viewMode === "invitations") {
            data = {
                eventId: eventId,
                inviteeEmail: user.inviteeEmail,
                eventName: `${eventData.eventName} - ${eventData.description}`,
            };
        } else {
            data = {
                eventId: eventId,
                inviteeEmail: user.email,
                eventName: `${eventData.eventName} - ${eventData.description}`,
            };
        }

        try {
            const response = await eventApi.sendEventInvitations(data);
            if (response) {
                if (viewMode === "attendees") {
                    const newAttendee = {
                        eventId: eventId,
                        attendeeEmail: user.email,
                        status: 0,
                    };
                    setAttendees([...attendees, newAttendee]);
                } else {
                    setInvitations(prevInvitations =>
                        prevInvitations.map(invite =>
                            invite.inviteeEmail === user.inviteeEmail
                                ? { ...invite, status: 0 }
                                : invite
                        )
                    );
                }
                notification.success({
                    message: "Success",
                    description: `Invitation sent successfully to ${data.inviteeEmail}.`,
                });
            }
        } catch (error) {
            console.log("Error adding attendee: ", error);
            notification.error({
                message: "Error",
                description: "There was an error sending the invitation.",
            });
        }
    };

    const handleRemoveAttendee = async (user) => {
        try {
            const attendee = attendees.find(att => att.attendeeEmail.toLowerCase() === user.email.toLowerCase());
            
            if (!attendee) {
                notification.error({
                    message: "Error",
                    description: "Attendee not found.",
                });
                return;
            }
    
            const response = await eventApi.deleteAttendeeById(attendee.id);
    
            if (response) {
                setAttendees(attendees.filter(att => att.id !== attendee.id));
                notification.success({
                    message: "Success",
                    description: `Attendee ${user.email} has been removed successfully.`,
                });
            }
        } catch (error) {
            console.log("Error removing attendee: ", error);
            notification.error({
                message: "Error",
                description: "There was an error removing the attendee.",
            });
        }
    };

    const allUsersColumn = [
        {
            title: "Profile",
            dataIndex: "profileImage",
            key: "profileImage",
            render: (text, record) => (
                <Avatar src={record.profileImage} alt={`${record.firstName} ${record.lastName}`} />
            ),
            onHeaderCell: () => ({
                style: { backgroundColor: "white", fontWeight: "bold" },
            }),
        },
        {
            title: "Name",
            dataIndex: "firstName",
            key: "firstName",
            render: (text, record) => `${record.firstName} ${record.lastName}`,
            onHeaderCell: () => ({
                style: { backgroundColor: "white", fontWeight: "bold" },
            }),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            onHeaderCell: () => ({
                style: { backgroundColor: "white", fontWeight: "bold" },
            }),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => {
                const isAttendee = attendees.some(attendee => 
                    attendee.attendeeEmail.toLowerCase() === record.email.toLowerCase()
                );
                
                return (
                    <Button 
                        onClick={() => isAttendee ? handleRemoveAttendee(record) : handleAddAttendee(record)}
                        style={isAttendee ? 
                            {color: "white", backgroundColor: "#2D9687", borderColor: "#2D9687"} : 
                            {color: "#2D9687", borderColor: "#2D9687"}}
                    >
                        {isAttendee ? "Remove" : "Add"}
                    </Button>
                );
            },
            onHeaderCell: () => ({
                style: { backgroundColor: "white", fontWeight: "bold" },
            }),
        },
    ];
    
    const invitesColumn = [
        {
            title: "Email",
            dataIndex: "inviteeEmail",
            key: "inviteeEmail",
            onHeaderCell: () => ({
                style: { backgroundColor: "white", fontWeight: "bold" },
            }),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            onHeaderCell: () => ({
                style: { backgroundColor: "white", fontWeight: "bold" },
            }),
            render: (text, record) => {
                return record.status === 0 ? "Pending" : "Accepted";
            }
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => handleAddAttendee(record)} style={{ borderColor: "#2D9687", color: "#2D9687" }}>
                        Resend
                    </Button>
                </Space>
            ),
            onHeaderCell: () => ({
                style: { backgroundColor: "white", fontWeight: "bold" },
            }),
        },
    ];

    const handleEmptyTable = () => {
        return (
            <>
            {viewMode === "attendees" ? (
                <div style={{ textAlign: "center" }}>
                    <Title level={4} style={{ color: "#2D9687" }}>
                        No attendees found
                    </Title>
                </div>
            ) : (
                <div style={{ textAlign: "center" }}>
                    <Title level={4} style={{ color: "#2D9687" }}>
                        No invitations found
                    </Title>
                </div>
            )}
            </>
        );
    }

    const content = (
        <div className="flex flex-col rounded">
            <span className="ml-2">
                List of all users in the database, you can select which user to add as attendee for the event.
            </span>
            <span className="ml-2">
                Automatically sends an invitation email to the user when added as an attendee.
            </span>
        </div>
    );

    const content2 = (
        <div className="flex flex-col">
            <span className="ml-2">
                List of all invitations sent for the event, you can resend the invitation to the user.
            </span>
        </div>
    );

    return (
        <>
            <text style={{ marginBottom: 20, fontSize: 19, fontWeight: "bold" }}>
                Event Attendees
            </text>

            <div style={{ backgroundColor: "white", padding: 5, borderRadius: 5 }}>
                <div style={{ margin: 16 }}>
                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        <Col span={12}>
                            <Input
                                placeholder={viewMode === "attendees" ? "Search all users" : "Search invitations"}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                prefix={<SearchOutlined />}
                                allowClear
                                style={{ backgroundColor: "white" }}
                            />
                        </Col>
                        <Col span={6}>
                            <Space size="middle">
                                <Popover content={viewMode === "attendees" ? content : content2} trigger="hover">
                                    <IoMdInformationCircleOutline style={{ fontSize: 25, color: "#2D9687" }} />
                                </Popover>
                            </Space>
                        </Col>
                        <Col span={6} style={{ textAlign: "right" }}>
                            <InvitationModal
                                invitations={invitations}
                                eventData={eventData}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        <Col span={24} style={{ textAlign: "center" }}>
                            <Radio.Group
                                value={viewMode}
                                onChange={(e) => setViewMode(e.target.value)}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="attendees" style={{ backgroundColor: viewMode === "attendees" ? "#2D9687" : "#f0f0f0", color: viewMode === "attendees" ? "white" : "black" }}>
                                    Attendees
                                </Radio.Button>
                                <Radio.Button value="invitations" style={{ backgroundColor: viewMode === "invitations" ? "#2D9687" : "#f0f0f0", color: viewMode === "invitations" ? "white" : "black" }}>
                                    Invitations
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>

                    {viewMode === "attendees" ? (
                        <>
                            <Table
                                columns={allUsersColumn}
                                dataSource={filteredUsers.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                                pagination={false}
                                locale={{ emptyText: handleEmptyTable }}
                                rowKey="key"
                            />

                            <Pagination
                                total={filteredUsers.length}
                                current={currentPage}
                                pageSize={pageSize}
                                onChange={handlePageChange}
                                style={{ marginTop: 16, textAlign: "right" }}
                            />
                        </>
                    ) : (
                        <>
                            <Table
                                columns={invitesColumn}
                                dataSource={filteredInvitations.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                                pagination={false}
                                locale={{ emptyText: handleEmptyTable }}
                                rowKey="id"
                            />

                            <Pagination
                                total={filteredInvitations.length}
                                current={currentPage}
                                pageSize={pageSize}
                                onChange={handlePageChange}
                                style={{ marginTop: 16, textAlign: "right", borderColor: "#2D9687", color: "#2D9687" }}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ManageAttendeesTab;
