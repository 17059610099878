import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import './style.css'


export const Eula = () => {
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className={'green-section'}>
                <div className={'header-case'}>Privacy Policy</div>
            </div>
            <div className='paragraph-padding'>

                <div className='paragraph-width'>

                    <h1 className='eula-title'>End User License Agreement (EULA)</h1>
                    <p>This End User License Agreement ("EULA") is a legal agreement between you ("you" or "User") and the African Hidden Champions ("we" or "us") for your access to and use of the African Hidden Champions (AHC) mobile application (the "App").</p>
                    <h2 className='eula-subtitle'>End User License Agreement (EULA)</h2>
                    <p>This End User License Agreement ("EULA") is a legal agreement between you ("you" or "User") and [Your Company Name] ("we" or "us") for your access to and use of the [Web App Name] web application (the "App").</p>

                    <h3 className='eula-subtitle'>1. Grant of License</h3>
                    <p>We grant you a non-exclusive, non-transferable, revocable license to access and use the App, subject to the terms and conditions of this EULA. This license is limited to the functionalities intended for your user type within the App.</p>

                    <h3 className='eula-subtitle'>2. User Content</h3>
                    <p>The App may allow certain users to submit, post, or display content, including text, data, images, videos, or other materials ("User Content"). You retain all ownership rights to your User Content. However, by submitting User Content to the App, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, publish, distribute, and translate your User Content in connection with the operation and maintenance of the App.</p>

                    <h3 className='eula-subtitle'>3. User Conduct</h3>
                    <p>
                        <b>
                            You agree to use the App in a lawful and ethical manner and will not:
                        </b>
                    </p>
                    <ul>
                        <li>Submit any User Content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive.</li>
                        <li>Violate any intellectual property rights of others.</li>
                        <li>Transmit any viruses, worms, Trojan horses, or other malicious code.</li>
                        <li>Disrupt or interfere with the security of the App.</li>
                        <li>Use the App for any unauthorized commercial purpose.</li>
                    </ul>

                    <h3 className='eula-subtitle'>4. Content Moderation</h3>
                    <p>We reserve the right, but are not obligated, to monitor, review, edit, or remove any User Content that we deem to violate this EULA or that we otherwise believe is inappropriate.</p>

                    <h3 className='eula-subtitle'>5. Third-Party Content</h3>
                    <p>The App may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the content or practices of any third-party website or service.</p>

                    <h3 className='eula-subtitle'>6. Disclaimer of Warranties</h3>
                    <p>THE APP IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>

                    <h3 className='eula-subtitle'>7. Limitation of Liability</h3>
                    <p>WE WILL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF YOUR USE OF THE APP, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.</p>

                    <h3 className='eula-subtitle'>8. Termination</h3>
                    <p>We may terminate this EULA and your access to the App at any time, with or without cause.</p>

                    <h3 className='eula-subtitle'>9. Entire Agreement</h3>
                    <p>This EULA constitutes the entire agreement between you and us regarding your access to and use of the App.</p>

                    <h3 className='eula-subtitle'>10. Governing Law</h3>
                    <p>This EULA shall be governed by and construed in accordance with the laws of [Your Jurisdiction].</p>

                    </div>
                </div>
                <Footer />
            </div>
            )
}