class UtilApi {
  constructor() {
    this.apiUrl = `${process.env.REACT_APP_BASE_URL}/Util/post`;
  }

  async convertBase64ToPng(baseString) {
    const obj = {
      base64Image: baseString,
    };

    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/base64ToImage`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(obj),
      });

      if (response.status >= 200 && response.status <= 300) {
        const url = await response.json();
        return url?.path;
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  }

  convertBase64ToPdf = async (pdf) => {
    const obj = {
      base64Pdf: pdf,
    };

    try {
      let headers = new Headers();
      headers.set("Content-Type", "application/json-patch+json;charset=UTF-8");
      let response = await fetch(`${this.apiUrl}/base64ToPdf`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(obj),
      });

      if (response.status >= 200 && response.status <= 300) {
        const url = await response.json();
        return url?.path;
      } else {
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
  };
}

const utilApi = new UtilApi();
export default utilApi;
