import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import './style.css'

export const Privacy = () => {
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
             <div className={'green-section'}>
                    <div className={'header-case'}>Privacy Policy</div>
            </div>
            <div className='paragraph-padding'>

                 <div className='paragraph-width'>
                    <h2 className='green-header'>Privacy Policy</h2>
                    <p className='page-text-privacy smaller-text'>Last updated: November 23, 2022</p>
                    <p className='page-text-privacy smaller-text'>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells Yo-privacy Your privacy rights and how the law protects You.</p>
                    <p className='page-text-privacy smaller-text'>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank" rel="noreferrer">Free Privacy Policy Generator</a>.</p>
                    <h1 className='green-header's>Interpretation and Definitions</h1>
                    <h3 className='green-header'>Interpretation</h3>
                    <p className='page-text-privacy smaller-text'>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                    <h3 className='green-header'>Definitions</h3>
                    <p className='page-text-privacy smaller-text'>For the purposes of this Privacy Policy:</p>
                    <ul className='summit-list smaller-text'>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named African Hidden Champions</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to African Hidden Champions Pty Ltd, 7 Bell Yard London, UK,.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Country</strong> refers to:  United Kingdom</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Service</strong> refers to the Application.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                    </li>
                    </ul>
                    <h3 className='green-header'>Collecting and Using Your Personal Data</h3>
                    <h2>Types of Data Collected</h2>
                    <h3>Personal Data</h3>
                    <p className='page-text-privacy smaller-text'>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                    <ul className='summit-list smaller-text'>
                    <li className='disc-list'>
                    <p>Email address</p>
                    </li>
                    <li className='disc-list'>
                    <p>First name and last name</p>
                    </li>
                    <li className='disc-list'>
                    <p>Phone number</p>
                    </li>
                    <li className='disc-list'>
                    <p>Usage Data</p>
                    </li>
                    </ul>
                    <h3 className='green-header'>Usage Data</h3>
                    <p className='page-text-privacy smaller-text'>Usage Data is collected automatically when using the Service.</p>
                    <p className='page-text-privacy smaller-text'>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                    <p className='page-text-privacy smaller-text'>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                    <p className='page-text-privacy smaller-text'>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                    <h3 className='green-header'>Information Collected while Using the Application</h3>
                    <p className='page-text-privacy smaller-text'>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
                    <ul className='summit-list smaller-text'>
                    <li className='disc-list'>Information regarding your location</li>
                    </ul>
                    <p className='page-text-privacy smaller-text'>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
                    <p className='page-text-privacy smaller-text'>You can enable or disable access to this information at any time, through Your Device settings.</p>
                    <h2 className='green-header'>Use of Your Personal Data</h2>
                    <p className='page-text-privacy smaller-text'>The Company may use Personal Data for the following purposes:</p>
                    <ul className='summit-list smaller-text'>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                    </li>
                    <li className='disc-list'>
                    <p className='page-text-privacy smaller-text'><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
                    </li>
                    </ul >
                    <h2 className='page-text-privacy smaller-text'>We may share Your personal information in the following situations:</h2>
                   
                    <ul className='summit-list smaller-text'>
                    <li className='disc-list'><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
                    <li className='disc-list'><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                    <li className='disc-list'><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                    <li className='disc-list'><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                    <li className='disc-list'><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                    <li className='disc-list'><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
                    </ul>
                    <h2 className='green-header'>Retention of Your Personal Data</h2>
                    <p className='page-text-privacy smaller-text'>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                    <p className='page-text-privacy smaller-text'>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                    <h2 className='green-header'>Transfer of Your Personal Data</h2>
                    <p className='page-text-privacy smaller-text'>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                    <p className='page-text-privacy smaller-text'>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                    <p className='page-text-privacy smaller-text'>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                    <h2 className='green-header'>Delete Your Personal Data</h2>
                    <p className='page-text-privacy smaller-text'>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
                    <p className='page-text-privacy smaller-text'>Our Service may give You the ability to delete certain information about You from within the Service.</p>
                    <p className='page-text-privacy smaller-text'>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
                    <p className='page-text-privacy smaller-text'>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
                    <h2 className='green-header'>Disclosure of Your Personal Data</h2>
                    <h3 className='green-header'>Business Transactions</h3>
                    <p className='page-text-privacy smaller-text'>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                    <h3 green-header>Law enforcement</h3>
                    <p className='page-text-privacy smaller-text'>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                    <h3 className='green-header'>Other legal requirements</h3>
                    <p className='page-text-privacy smaller-text'>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                    <ul className='summit-list smaller-text'>
                    <li className='disc-list'>Comply with a legal obligation</li>
                    <li className='disc-list'>Protect and defend the rights or property of the Company</li>
                    <li className='disc-list'>Prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li className='disc-list'>Protect the personal safety of Users of the Service or the public</li>
                    <li className='disc-list'>Protect against legal liability</li>
                    </ul>
                    <h2 className='green-header'>Security of Your Personal Data</h2>
                    <p className='page-text-privacy smaller-text'>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                    <h1 className='green-header'>Children's Privacy</h1>
                    <p className='page-text-privacy smaller-text'>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                    <p className='page-text-privacy smaller-text'>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                    <h1 className='green-header'>Links to Other Websites</h1>
                    <p className='page-text-privacy smaller-text'>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                    <p className='page-text-privacy smaller-text'>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                    <h1 className='green-header'>Changes to this Privacy Policy</h1>
                    <p className='page-text-privacy smaller-text'>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                    <p className='page-text-privacy smaller-text'>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
                    <p className='page-text-privacy smaller-text'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    <h1 className='green-header'>Contact Us</h1>
                    <p className='page-text-privacy smaller-text'>If you have any questions about this Privacy Policy, You can contact us:</p>
                    <ul className='summit-list smaller-text'>
                    <li className='disc-list'>By email: bernard.adjei@africaforesight.com</li>
                    </ul>
                </div>
            </div>
            <Footer/>
        </div>
    )
}