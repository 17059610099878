import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing } from "./pages/landing";
import { About } from "./pages/about";
import { People } from "./pages/people";
import { Join } from "./pages/join";
import { Summit } from "./pages/summit";
import { Privacy } from "./pages/Privacy-Policy";
import { Eula } from "./pages/eula";
import { CaseStudies } from "./pages/case-studies";
import { Nominate } from "./pages/nominate";
import { Gallery } from "./pages/gallery";
import { CaseUBIEnergy } from "./pages/case-ubi-energy";
import { DAWASciences } from "./pages/Dawa-Sciences";
import { MAHGOUBSONS } from "./pages/Mahgoub-Sons-Group";
import { NOVAPIONNER } from "./pages/Nova-Pioneer";
import { SORBETGROUP } from "./pages/Sorbet-Group";
import { PUPKEWITZ } from "./pages/Pupkewitz-Group";
import reportWebVitals from "./reportWebVitals";
import { NICHE } from "./pages/Niche-Cocoa";
import { EDITA } from "./pages/Edita-Foods";
import { CLEOPATRA } from "./pages/Cleopatra-Hospital-Group";
import { MFS } from "./pages/MFS";
import { Login } from "../src/pages/admin";
import { Dashboard } from "../src/pages/adminDashboard";
import { AuthProvider } from "./Provider/Authcontext";
import { ProtectedRoute } from "./protectedRoute";
import 'rsuite/dist/rsuite.min.css';
import { CustomProvider } from 'rsuite';
import "./index.css";
// import 'antd/dist/antd.css'

ReactDOM.render(
  <React.StrictMode>
    <CustomProvider>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="about" element={<About />} />
          <Route path="/people" element={<People />} />
          <Route path="/join" element={<Join />} />
          <Route path="/summit" element={<Summit />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/eula" element={<Eula />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/nominate" element={<Nominate />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/case-studies/ubi-energy" element={<CaseUBIEnergy />} />
          <Route
            path="/case-studies/Dawa-sciences"
            element={<DAWASciences />}
          />
          <Route path="/case-studies/maghoub-group" element={<MAHGOUBSONS />} />
          <Route path="/case-studies/Nova-pionner" element={<NOVAPIONNER />} />
          <Route path="/case-studies/sorbet-energy" element={<SORBETGROUP />} />
          <Route path="/case-studies/Pupkewitz-Group" element={<PUPKEWITZ />} />
          <Route path="/case-studies/Niche-Cocoa" element={<NICHE />} />
          <Route path="/case-studies/Edita-Foods" element={<EDITA />} />
          <Route path="/case-studies/MFS" element={<MFS />} />
          <Route path="/case-studies/Cleopatra-Group" element={<CLEOPATRA />} />

          <Route path="/admin" element={<Login />} />

          <Route element={<ProtectedRoute/>}>
            <Route path="/adminDashboard/*" element={<Dashboard />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </AuthProvider>
    </CustomProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();