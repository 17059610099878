import React from 'react';
import { Outlet,useNavigate } from 'react-router-dom';

export const ProtectedRoute = () => {
    
    const localRole = localStorage.getItem("userRole");
    const nav = useNavigate()
    if (!localRole || localRole === "null") {
        console.log(localRole)
        return nav("/admin");
    }
    else{
        console.log(localRole)
        return <Outlet/>;
    }

}

