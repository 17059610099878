import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
  Grid,
  Collapse,
} from '@mui/material';
import { MdExpandMore, MdEdit, MdSave, MdCancel } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from 'dayjs';
import programSlotsApi from '../../../api/ProgramSlotsApi';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { notification } from "antd";
import SpeakerModal from "./SpeakerModal.js";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#2D9687",
    },
  },
});

const PostedItineraryView = ({ eventData, eventDays, speakersData }) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const [expandedProgram, setExpandedProgram] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [editedData, setEditedData] = useState({});
  const [programSlots, setProgramSlots] = useState([]);
  const [localProgramSlots, setLocalProgramSlots] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [currentEditingSlot, setCurrentEditingSlot] = useState(null);
  const [timeErrors, setTimeErrors] = useState({});

  const fetchProgramSlots = async () => {
    const slots = await programSlotsApi.getProgramSlotsByEventId(eventData.id);
    setProgramSlots(slots);
  };

  useEffect(() => {
    fetchProgramSlots();
  }, [eventData.id]);

  useEffect(() => {
    const initialLocalSlots = {};
    eventDays.forEach(day => {
      initialLocalSlots[day.id] = day.programSlots;
    });
    setLocalProgramSlots(initialLocalSlots);
  }, []);

  const handleDayChange = (index) => {
    setSelectedDay(index);
    setExpandedProgram(null);
  };

  const handleExpandClick = (programIndex) => {
    setExpandedProgram(expandedProgram === programIndex ? null : programIndex);
  };

  const handleEdit = (dayId, slotId) => {
    setEditMode({ ...editMode, [`${dayId}-${slotId}`]: true });
    setEditedData({
      ...editedData,
      [`${dayId}-${slotId}`]: eventDays[selectedDay].programSlots.find(slot => slot.id === slotId)
    });
  };

  const validateTimes = (startTime, endTime) => {
    if (!startTime || !endTime) {
      notification.error({ message: "Both start and end times are required" });
      return false;
    }

    const start = dayjs.unix(startTime);
    const end = dayjs.unix(endTime);

    if (end.isBefore(start) || end.isSame(start)) {
      notification.error({ message: "End time must be after start time" });
      return false;
    }

    return true;
  };

  const handleChange = (dayId, slotId, field, value) => {
    const updatedData = {
      ...editedData,
      [`${dayId}-${slotId}`]: {
        ...editedData[`${dayId}-${slotId}`],
        [field]: value
      }
    };

    if (field === 'startTime' || field === 'endTime') {
      const { startTime, endTime } = updatedData[`${dayId}-${slotId}`];
      if (startTime && endTime) {
        validateTimes(startTime, endTime);
      }
    }

    setEditedData(updatedData);
  };

  const handleAddProgramSlot = () => {
    const currentDay = eventDays[selectedDay];
    const currentDaySlots = localProgramSlots[currentDay.id] || [];
    const lastSlot = currentDaySlots[currentDaySlots.length - 1];
    
    let newStartTime, newEndTime;
    
    if (lastSlot && lastSlot.endTime) {
      newStartTime = lastSlot.endTime;
      newEndTime = newStartTime + 3600;
    } else {
      const now = Math.floor(Date.now() / 1000);
      newStartTime = now;
      newEndTime = now + 3600;
    }

    const newSlot = {
      id: `temp-${currentDaySlots.length + 1}`,
      eventId: eventData.id,
      eventDayId: currentDay.id,
      title: `Event Program ${currentDaySlots.length + 1}`,
      description: "",
      startTime: newStartTime,
      endTime: newEndTime,
      speakerId: null,
      dateAdded: Math.floor(Date.now() / 1000),
    };

    setLocalProgramSlots(prevSlots => ({
      ...prevSlots,
      [currentDay.id]: [...(prevSlots[currentDay.id] || []), newSlot]
    }));
    setExpandedProgram(currentDaySlots.length);
    
    setEditMode(prevEditMode => ({
      ...prevEditMode,
      [`${currentDay.id}-${newSlot.id}`]: true
    }));
    
    setEditedData(prevEditedData => ({
      ...prevEditedData,
      [`${currentDay.id}-${newSlot.id}`]: newSlot
    }));
  };

  const handleRemoveProgramSlot = async (slotId) => {
    try {
      const currentDay = eventDays[selectedDay];
      if (currentDay.programSlots.length <= 1) {
        notification.warning({ message: "Cannot remove the last program slot" });
        return;
      }

      await programSlotsApi.deleteProgramSlot(slotId);
      await fetchProgramSlots();
      
      setLocalProgramSlots(prevSlots => ({
        ...prevSlots,
        [currentDay.id]: prevSlots[currentDay.id].filter(slot => slot.id !== slotId)
      }));

      notification.success({ message: "Program slot removed successfully" });
    } catch (error) {
      console.error("Error removing program slot:", error);
      notification.error({ message: "Failed to remove program slot" });
    }
  };

  const handleSave = async (dayId, slotId) => {
    try {
      let updatedSlot = editedData[`${dayId}-${slotId}`];
      const currentSlot = localProgramSlots[dayId].find(slot => slot.id === slotId);
      
      updatedSlot = {
        ...updatedSlot,
        speakerId: selectedSpeaker?.id || currentSlot?.speakerId
      };

      if (!updatedSlot.title || !updatedSlot.description || !updatedSlot.startTime || !updatedSlot.endTime || !updatedSlot.speakerId) {
        let missingFields = [];
        if (!updatedSlot.title) missingFields.push("Title");
        if (!updatedSlot.description) missingFields.push("Description");
        if (!updatedSlot.startTime) missingFields.push("Start Time");
        if (!updatedSlot.endTime) missingFields.push("End Time");
        if (!updatedSlot.speakerId) missingFields.push("Speaker");
        notification.error({ message: `Please fill in required field for ${updatedSlot.title}: ${missingFields.join(", ")}` });

        return;
      }

      if (!validateTimes(updatedSlot.startTime, updatedSlot.endTime)) {
        return;
      }

      updatedSlot = {
        ...currentSlot,
        ...updatedSlot,
        speakerId: selectedSpeaker?.id ? selectedSpeaker?.id : currentSlot?.speakerId,
        eventDayId: dayId
      };

      let savedSlot;
      if (slotId.toString().startsWith('temp-')) {
        const { id, ...newSlotData } = updatedSlot;
        savedSlot = await programSlotsApi.createProgramSlot(newSlotData);
        if (savedSlot && savedSlot.id) {
          notification.success({ message: "New program slot created successfully" });
        }
      } else {
        savedSlot = await programSlotsApi.updateProgramSlot(slotId, updatedSlot);
        console.log("savedSlot: ", savedSlot);
        if(savedSlot) {
          notification.success({ message: "Program slot updated successfully" });
        }
      }

      if (savedSlot) {
        setLocalProgramSlots(prevSlots => ({
          ...prevSlots,
          [dayId]: prevSlots[dayId].map(slot => slot.id === slotId ? savedSlot : slot)
        }));
        setEditMode({ ...editMode, [`${dayId}-${slotId}`]: false });
        await fetchProgramSlots();
        
        setProgramSlots(prevSlots => prevSlots.map(slot => slot.id === slotId ? savedSlot : slot));
      }
    } catch (error) {
      console.error("Error saving program slot:", error);
      notification.error({
        message: 'Failure',
        description: `Failed to save program slot.`,
    });
    }
  };

  
  const handleCancel = (dayId, slotId) => {
    setEditMode({ ...editMode, [`${dayId}-${slotId}`]: false });
  };

  const handleOpenModal = (slot) => {
    setOpenModal(true);
    setCurrentEditingSlot(slot);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentEditingSlot(null);
  };

  const handleSelectSpeaker = (speaker) => {
    setSelectedSpeaker(speaker);
  };

  const handleConfirmSpeaker = async () => {
    if (selectedSpeaker && currentEditingSlot) {
      try {
        const updatedSlot = {
          ...currentEditingSlot,
          speakerId: selectedSpeaker.id
        };
        await programSlotsApi.updateProgramSlot(currentEditingSlot.id, updatedSlot);
        fetchProgramSlots();
        handleCloseModal();
      } catch (error) {
        console.error("Error updating speaker:", error);
        notification.error({ message: "Failed to update speaker" });
      }
    }
  };

  const validateSlotTime = (dayIndex, slotIndex, field, newValue) => {
    const currentDay = eventDays[dayIndex];
    const currentSlot = currentDay.programSlots[slotIndex];
    const previousSlot = slotIndex > 0 ? currentDay.programSlots[slotIndex - 1] : null;
    const isLastDay = dayIndex === eventDays.length - 1;
    const isLastSlot = slotIndex === currentDay.programSlots.length - 1;

    let isValid = true;
    let errorMessage = '';

    if (previousSlot) {
      const previousTime = field === 'startTime' ? previousSlot.endTime : currentSlot.startTime;
      const previousDayjs = dayjs.unix(previousTime);
      
      if (newValue.isBefore(previousDayjs)) {
        isValid = false;
        errorMessage = `Time must be after ${previousDayjs.format('HH:mm')}`;
      }
    }

    if (isLastDay && isLastSlot && field === 'endTime') {
      const eventEndDate = dayjs.unix(eventData.endDate);
      if (newValue.isAfter(eventEndDate)) {
        isValid = false;
        errorMessage = `Time cannot exceed event end date (${eventEndDate.format('YYYY-MM-DD HH:mm')})`;
      }
    }

    return { isValid, errorMessage };
  };

  const handleTimeChange = (dayIndex, slotIndex, field, newValue) => {
    const currentDay = eventDays[dayIndex];
    const currentSlot = currentDay.programSlots[slotIndex];

    const { isValid, errorMessage } = validateSlotTime(dayIndex, slotIndex, field, newValue);

    setTimeErrors(prev => ({
      ...prev,
      [dayIndex]: {
        ...prev[dayIndex],
        [slotIndex]: {
          ...prev[dayIndex]?.[slotIndex],
          [field]: isValid ? null : errorMessage
        }
      }
    }));

    if (isValid) {
      handleChange(currentDay.id, currentSlot.id, field, newValue.unix());
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box display="flex">
        <Box display="flex" flexDirection="column" width="23%" padding="10px">
          {eventDays?.map((day, index) => (
            <Button
              key={day.id}
              onClick={() => handleDayChange(index)}
              sx={{
                backgroundColor: index === selectedDay ? "#2D9687" : "#FFFFFF",
                color: index === selectedDay ? "#FFFFFF" : "#000000",
                marginBottom: "10px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: index === selectedDay ? "#2D9687" : "#FFFFFF",
                },
              }}
            >
              {day?.name}
            </Button>
          ))}
        </Box>

        <Box flex="1" padding="20px">
          <Typography variant="h6" fontWeight="bold" marginBottom={2}>
            Program details
            <span style={{color: "#2D9687", marginLeft: 15}}>
              {eventData?.eventName}
            </span>
          </Typography>

          {localProgramSlots[eventDays[selectedDay]?.id]?.map((slot, index) => (
            <Card key={slot?.id} variant="outlined" sx={{ marginBottom: 2 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => handleExpandClick(index)}
                sx={{ cursor: "pointer" }}
              >
                <CardContent>
                  <Typography variant="h6" color={"primary"} fontWeight="bold">{slot.title}</Typography>
                </CardContent>
                <MdExpandMore
                  style={{
                    marginRight: "20px",
                    fontSize: "30px",
                    color: "#575757",
                    transform: expandedProgram === index ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </Box>

              <Collapse in={expandedProgram === index}>
                <CardContent>
                  {editMode[`${eventDays[selectedDay]?.id}-${slot.id}`] ? (
                    <Box>
                      <TextField
                        fullWidth
                        label="Title"
                        value={editedData[`${eventDays[selectedDay].id}-${slot.id}`]?.title}
                        onChange={(e) => handleChange(eventDays[selectedDay].id, slot.id, 'title', e.target.value)}
                        defaultValue={slot.title}
                        margin="normal"
                        required
                      />
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label="Description"
                        value={editedData[`${eventDays[selectedDay].id}-${slot.id}`]?.description}
                        onChange={(e) => handleChange(eventDays[selectedDay].id, slot.id, 'description', e.target.value)}
                        margin="normal"
                        required
                      />
                      <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Start Time"
                              value={dayjs.unix(editedData[`${eventDays[selectedDay].id}-${slot.id}`]?.startTime)}
                              onChange={(newValue) => handleTimeChange(selectedDay, index, 'startTime', newValue)}
                              required
                              minTime={dayjs().startOf('day')}
                              maxTime={dayjs().endOf('day')}
                              slotProps={{
                                textField: {
                                  helperText: timeErrors[selectedDay]?.[index]?.startTime,
                                  error: !!timeErrors[selectedDay]?.[index]?.startTime,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="End Time"
                              value={dayjs.unix(editedData[`${eventDays[selectedDay].id}-${slot.id}`]?.endTime)}
                              onChange={(newValue) => handleTimeChange(selectedDay, index, 'endTime', newValue)}
                              required
                              minTime={dayjs.unix(editedData[`${eventDays[selectedDay].id}-${slot.id}`]?.startTime).add(1, 'minute')}
                              maxTime={dayjs().endOf('day')}
                              slotProps={{
                                textField: {
                                  helperText: timeErrors[selectedDay]?.[index]?.endTime,
                                  error: !!timeErrors[selectedDay]?.[index]?.endTime,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <Typography variant="h6" mt={2}>Speaker</Typography>
                      {(slot.speakerId || selectedSpeaker) && (
                        <Box display="flex" alignItems="center" mt={1}>
                          {(selectedSpeaker?.profilePic || programSlots?.find(ps => ps.id === slot.id)?.speaker?.profilePic) ? (
                            <img
                              src={selectedSpeaker?.profilePic || programSlots?.find(ps => ps.id === slot.id)?.speaker?.profilePic}
                              alt={selectedSpeaker?.name || programSlots?.find(ps => ps.id === slot.id)?.speaker?.name}
                              width="40"
                              height="40"
                              style={{ borderRadius: "50%", marginRight: "10px" }}
                            />
                          ) : (
                            <FaUserCircle size={40} style={{ marginRight: "10px" }} />
                          )}
                          <Typography variant="body2">
                            {selectedSpeaker?.name || programSlots?.find(ps => ps.id === slot.id)?.speaker?.name}
                          </Typography>
                        </Box>
                      )}

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenModal(slot)}
                        sx={{ marginTop: "10px" }}
                      >
                        {programSlots?.find(ps => ps.id === slot.id)?.speakerId ? "Change Speaker" : "Add Speaker"}
                      </Button>
                      <Box mt={2}>
                        <Button startIcon={<MdSave />} variant="contained" color="primary" onClick={() => handleSave(eventDays[selectedDay].id, slot.id)} sx={{ marginRight: 1 }}>Save</Button>
                        <Button startIcon={<MdCancel />} variant="outlined" color="primary" onClick={() => handleCancel(eventDays[selectedDay].id, slot.id)}>Cancel</Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="body2" color="text.secondary">{slot?.description}</Typography>
                      {slot?.speakerId && programSlots?.find(ps => ps.id === slot.id)?.speaker && (
                        <Box display="flex" alignItems="center" mt={1}>
                          {programSlots?.find(ps => ps.id === slot.id).speaker.profilePic ? (
                            <img
                              src={programSlots?.find(ps => ps.id === slot.id).speaker.profilePic}
                              alt={programSlots?.find(ps => ps.id === slot.id).speaker.name}
                              width="30"
                              height="30"
                              style={{ borderRadius: "50%", marginRight: "10px" }}
                            />
                          ) : (
                            <FaUserCircle size={40} style={{ marginRight: "10px" }} />
                          )}
                          <Typography variant="body2">
                            {programSlots?.find(ps => ps.id === slot.id).speaker.name}
                          </Typography>
                        </Box>
                      )}
                      
                      <Typography variant="body2" color="text.secondary" marginTop={2}>
                        <span style={{fontWeight: "bold"}}>
                          Time: {" "}
                        </span>
                        {dayjs.unix(slot?.startTime).format('HH:mm')} - {dayjs.unix(slot?.endTime).format('HH:mm')}
                      </Typography>
                      <IconButton onClick={() => handleEdit(eventDays[selectedDay].id, slot.id)} color="primary">
                        <MdEdit />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleRemoveProgramSlot(slot.id)} 
                        color="primary"
                        disabled={eventDays[selectedDay].programSlots.length <= 1}
                      >
                        <AiOutlineMinusCircle />
                      </IconButton>
                    </Box>
                  )}
                </CardContent>
              </Collapse>
            </Card>
          ))}
          <Box display="flex" justifyContent="center" mt={2}>
            <IconButton onClick={handleAddProgramSlot} color="primary">
              <AiOutlinePlusCircle size={24} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <SpeakerModal
        open={openModal}
        onClose={handleCloseModal}
        speakersData={speakersData}
        selectedSpeaker={selectedSpeaker}
        onSelectSpeaker={handleSelectSpeaker}
        onConfirmSpeaker={handleConfirmSpeaker}
        customTheme={customTheme}
      />
    </ThemeProvider>
  );
};

export default PostedItineraryView;