import React, { useEffect } from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { BiSearchAlt2 } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import './style.css'
import '../page-style.css'
import ubi_logo from '../../assets/images/UBI-large.png'
import maghoub_large from '../../assets/images/maghoub-large.png'
import sorbet_large from '../../assets/images/Sorbet-large.png'
import dawa_large from '../../assets/images/Dawa-large.png'
import nova_large from '../../assets/images/nova-large.png'
import pupk_large from '../../assets/images/Pupk-large.png'
import Niche_Cocoa from '../../assets/images/Niche_Cocoa.png'
import Edita_foods from '../../assets/images/Edita-foods.jpeg'
import Cleopatra_Group from '../../assets/images/CHG-Logo.jpeg'
import africa from '../../assets/images/africa.jpeg';
const casesArray = [
    { heading: "MFS", subHeading: "MFS", text: "Case Studies", path: "MFS", logo: africa, category:"AHC" },
    { heading: "Niche Cocoa", subHeading: "Niche Case Study", text: "Case Studies", path: "Niche-Cocoa", logo: Niche_Cocoa, category:"AHC" },
    { heading: "Edita Foods", subHeading: "Edita Foods", text: "Case Studies", path: "Edita-Foods", logo: Edita_foods, category:"AHC" },
    { heading: "Cleopatra Hospital Group", subHeading: "Cleopatra Hospital Group", text: "Case Studies", path: "Cleopatra-Group", logo: Cleopatra_Group, category:"AHC" },
    { heading: "UBI Energy", subHeading: "UBI Energy", text: "Case Studies", path: "ubi-energy", logo: ubi_logo, category:"AHC" },
    { heading: "Maghoub Sons Group", subHeading: "Maghoub Sons Group", text: "Case Studies", path: "maghoub-group", logo: maghoub_large, category:"AHC" },
    { heading: "Sorbet Group", subHeading: "Sorbet Group", text: "Case Studies", path: "sorbet-energy", logo: sorbet_large, category:"AHC" },
    { heading: "Dawa Life Sciences", subHeading: "Dawa Life Sciences", text: "Case Studies", path: "Dawa-sciences", logo: dawa_large, category:"AHC" },
    { heading: "Nova-pioneer", subHeading: "Nova-pioneer", text: "Case Studies", path: "Nova-pionner", logo: nova_large, category:"AHC" },
    { heading: "Pupkewitz Group", subHeading: "Pupkewitz Group", text: "Case Studies", path: "Pupkewitz-Group", logo: pupk_large, category:"AHC" },
]

// function Search({ filteredcomany }) {
//     const filtered = filteredcomany.map(caseItem =>  caseItem.heading === searched); 
//     return (
//       <div>
//         const navigate = useNavigate(); 
//       </div>
// }   );
export const CaseStudies = () => {
    const [selectedCompany, setSelectedCompany] = React.useState("")
    const [selectedCategory, setSelectedCategory] = React.useState("")
    const [selectedCaseType, setSelectedCaseType] = React.useState("")
    const [filteredCases, setFilteredCases] = React.useState(casesArray)
    const [searchText,setSearchText] = React.useState("")
    

    useEffect(() => {
        if (searchText !== "") {
            let newArr = casesArray.filter(caseItem => caseItem.heading.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
            console.log("newArr:", newArr)
            setFilteredCases(newArr);
        }else{
            setFilteredCases(casesArray);
        }
    }, [searchText])

    useEffect(() => {
        if (selectedCompany !== "") {
            console.log(selectedCompany)
            let newArr = casesArray.filter(caseItem => caseItem.heading === selectedCompany)
            console.log("newArr:", newArr)
            setFilteredCases(newArr);
        }else{
            setFilteredCases(casesArray);
        }
    }, [selectedCompany])

    useEffect(() => {
        if (selectedCaseType !== "") {
            let newArr = casesArray.filter(caseItem => caseItem.text === selectedCaseType)
            console.log("newArr:", newArr)
            setFilteredCases(newArr);
        }else{
            setFilteredCases(casesArray);
        }
    }, [selectedCaseType])

    useEffect(() => {
        if (selectedCategory !== "") {
            let newArr = casesArray.filter(caseItem => caseItem.category === selectedCategory)
            console.log("newArr:", newArr)
            setFilteredCases(newArr);
        }else{
            setFilteredCases(casesArray);
        }
    }, [selectedCategory])

    const handleSearchInput = (inputText) =>{
        setSearchText(inputText)
    }

    const navigate = useNavigate();
    return (
        <div className={'main'}>
            <Navbar whiteText={false} className={'home-navbar'} />
            <div className={'case-studies-section'}>
                <div className='case-overlay'></div>
                <div className='case-study-heading'>Case Studies</div>
                <div className={'search-container'}>
                    <input placeholder="Search" type="text" className={'search-case'} value={searchText} onChange={(event)=>handleSearchInput(event.target.value)}></input>
                    <BiSearchAlt2 className={'search-case-icon'} />
                    
                </div>
            </div>
            <div className="cases-contain">
                <div className="cases-column">
                    <select onChange={event => setSelectedCategory(event.target.value)} value={selectedCategory} className={"case-select"}>
                        <option value="">Categories</option>
                        <option value="AHC">AHC</option>
                    </select>
                    <select onChange={event => setSelectedCaseType(event.target.value)} value={selectedCaseType} className={"case-select"}>
                        <option value="">Case Type</option>
                        <option value="Case Studies">Case Study</option>
                    </select>
                    <select onChange={event => setSelectedCompany(event.target.value)} value={selectedCompany} className={"case-select"}>
                        <option value={""}>Company</option>
                        {
                            casesArray.map((case_study, i) => (
                                <option value={case_study.heading}>{case_study.heading}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="cases-grid">
                    {filteredCases.map((caseItem, index) => (
                        <div key={index} onClick={() => navigate(caseItem.path)} className='shadow-3 case-company-card'>
                            <img alt="ubi_logo" className="case-logo" src={caseItem.logo} />
                            <h2 className="case-card-heading">{caseItem.heading}</h2>
                            <div className='case-card-subheading'>{caseItem.subHeading}</div>
                            <div className='case-card-text'>Case Studies</div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}